import styled from '@mui/system/styled';

const CheckCircleValid = styled(props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.3"
      d="M9.99992 3.33331C6.32492 3.33331 3.33325 6.32498 3.33325 9.99998C3.33325 13.675 6.32492 16.6666 9.99992 16.6666C13.6749 16.6666 16.6666 13.675 16.6666 9.99998C16.6666 6.32498 13.6749 3.33331 9.99992 3.33331ZM8.33325 14.1666L4.99992 10.8333L6.17492 9.65831L8.33325 11.8083L13.8249 6.31665L14.9999 7.49998L8.33325 14.1666Z"
      fill="#2E7D32"
    />
    <path
      d="M10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.675 3.33341 10C3.33341 6.32502 6.32508 3.33335 10.0001 3.33335C13.6751 3.33335 16.6667 6.32502 16.6667 10C16.6667 13.675 13.6751 16.6667 10.0001 16.6667ZM13.8251 6.31669L8.33342 11.8084L6.17508 9.65835L5.00008 10.8334L8.33342 14.1667L15.0001 7.50002L13.8251 6.31669Z"
      fill="black"
    />
  </svg>
))({});

export default CheckCircleValid;
