import { FC, memo } from 'react';
import DocumentPreview from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/DocumentPreview';
import PreviewControls from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/PreviewControls';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';

import { Box, CircularProgress } from '@mui/material';

const PreviewColumn: FC = () => {
  const { fileBlob, fileName, fileType, isLoadingPreview } = useInvoiceDetailsNewContext();
  return (
    <>
      {isLoadingPreview && (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress size={80} color="primary" />
        </Box>
      )}
      {!isLoadingPreview && fileBlob && (
        <Box m={3} display="flex" flexDirection="column" gap={3} maxWidth={500}>
          <PreviewControls blob={fileBlob} fileName={fileName} buttonHeight="30px" fontSize={13} />
          <DocumentPreview blob={fileBlob} fileName={fileName} fileType={fileType} />
        </Box>
      )}
    </>
  );
};

export default memo(PreviewColumn);
