import { Alert, AlertProps, Button, ButtonProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledAlert = styled(Alert)<AlertProps & { theme?: Theme }>(({ theme }) => ({
  '& .MuiAlert-message': { flexGrow: 1 },
  '& .MuiAlert-icon': { alignItems: 'center' },
  boxShadow: 'none',
  backgroundColor: theme.colors.background.issueBackground,
  color: theme.colors.action.issueText,
  maxWidth: '600px',
}));

export const StyledEditButton = styled(Button)<ButtonProps & { theme?: Theme }>(({ theme }) => ({
  color: theme.colors.action.issueText,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  boxShadow: 'none',
}));
