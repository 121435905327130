import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import CheckCircleValid from '@components/icons/CheckCirceValid';
import CheckCircleInvalid from '@components/icons/CheckCircleInvalid';

import { StyledListTypography } from './styled';

interface SignUpFormValidationProps {
  type: string;
  isTouched: boolean;
  isError: boolean;
}

const PasswordValidationItem: FC<SignUpFormValidationProps> = ({ type, isError, isTouched }) => {
  const { t } = useTranslation();

  const isValid = isTouched && !isError;

  return (
    <Box display="flex" gap={3} my={2}>
      {isValid ? <CheckCircleValid /> : <CheckCircleInvalid />}
      <StyledListTypography component="li" variant="body2" isValid={isValid} isError={isError}>
        {t(`common.passwordRequirements.${type}`)}
      </StyledListTypography>
    </Box>
  );
};

export default memo(PasswordValidationItem);
