import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, InputProps, TextField, TextFieldProps, Typography } from '@mui/material';

import { StyledIconButton } from './styled';

type InputPasswordProps = Omit<TextFieldProps, 'type' | 'InputProps'> & { input?: Omit<InputProps, 'endAdornment'> };

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(({ input, disabled, ...mui }, ref) => {
  const [isShownPassword, setShownPassword] = useState(false);
  const { t } = useTranslation();
  const togglePassword = useCallback(() => {
    if (!disabled) {
      setShownPassword(prevState => !prevState);
    }
  }, []);

  return (
    <TextField
      ref={ref}
      type={isShownPassword ? 'text' : 'password'}
      disabled={disabled}
      InputProps={{
        ...input,
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <StyledIconButton onClick={togglePassword} disableRipple disableFocusRipple disableTouchRipple>
              <Typography variant="body1" mr={1}>
                {isShownPassword ? t('common.hide').toUpperCase() : t('common.show').toUpperCase()}
              </Typography>
              {!isShownPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </StyledIconButton>
          </InputAdornment>
        ),
      }}
      {...mui}
      sx={{
        '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
          transform: 'translate(43px, 17px)',
        },
      }}
    />
  );
});

export default InputPassword;
