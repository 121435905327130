import { Link, LinkProps } from 'react-router-dom';

import {
  CircularProgress,
  CircularProgressProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledTextField = styled(TextField)<TextFieldProps>({
  margin: '10px 0',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(43px, 17px)',
  },
});

export const StyledLink = styled(Link)<LinkProps & { component?: typeof Link }>(({ theme }) => ({
  color: theme.colors.primary.main,
  fontWeight: 500,
  fontSize: '15px',
  textDecoration: 'none',
}));

export const StyledTypography = styled(Typography)<TypographyProps & { theme?: Theme }>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));

export const StyledCircularProgress = styled(CircularProgress)<CircularProgressProps & { theme?: Theme }>(
  ({ theme }) => ({
    color: theme.colors.primary.main,
  }),
);
