import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledTextButton } from '@pages/InvoiceDetailsNew/components/InfoColumn/styled';

import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { StyledModal } from '../styled';

const AddNumberModal = NiceModal.create(
  ({
    title,
    helperText,
    label,
    setNumber,
    updateMutation,
    mutationIsLoading,
  }: {
    title: string;
    helperText?: string;
    label: string;
    setNumber: (value: string) => void;
    updateMutation: (ref_number: string) => Promise<void>;
    mutationIsLoading: boolean;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const [inputValue, setInputValue] = useState<string>(''); // Local state for the input field

    const handleConfirmClick = async () => {
      if (inputValue) {
        setNumber(inputValue);
        await updateMutation(inputValue);
      }

      modal.resolve(true);
      modal.remove();
    };

    const handleCancelClick = () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onClose={handleCancelClick}>
        <Box padding="16px 24px">
          <Typography variant="h6" fontFamily="WFVisualSans" fontWeight={500} mb={2}>
            {t(title)}
          </Typography>

          <Box>
            <TextField
              fullWidth
              label={label}
              variant="outlined"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            {helperText && <FormHelperText style={{ marginLeft: '15px' }}>{helperText}</FormHelperText>}
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <StyledTextButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="text"
              color="primary"
              onClick={handleCancelClick}
            >
              {t('common.cancel')}
            </StyledTextButton>
            <LoadingButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="contained"
              disabled={!inputValue}
              loading={mutationIsLoading}
              color="primary"
              onClick={handleConfirmClick}
              type="submit"
              sx={{ maxHeight: '35px' }}
            >
              {t('common.save')}
            </LoadingButton>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const AddNumberModalId = 'AddNumberModal';

NiceModal.register(AddNumberModalId, AddNumberModal);
