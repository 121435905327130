import { useCallback } from 'react';

export const useAnimateAutofill = () => {
  const makeAnimationStartHandler = useCallback(
    (stateSetter: any) => (e: any) => {
      const autofilled = !!e.target?.matches('*:-webkit-autofill');
      if (e.animationName === 'mui-auto-fill') {
        stateSetter(autofilled);
      }

      if (e.animationName === 'mui-auto-fill-cancel') {
        stateSetter(autofilled);
      }
    },
    [],
  );

  return { makeAnimationStartHandler };
};
