import { FC, memo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { api, useAuthSigninCreateMutation, useEmailsInvoiceCreateMutation } from '@api/api';
import { EMAIL_DOMAIN, ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import CreateIDForm from '@pages/CreateID/components/CreateIDForm';
import { useCreateIdContext } from '@pages/CreateID/context';
import { confirmSuccess, loginSuccess } from '@store/authSlice';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

const CreateID: FC = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { createIdForm, toggleEdit } = useCreateIdContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const email = state?.email as string;
  const password = state?.password as string;

  const [loginMutation, { isLoading: loginIsLoading }] = useAuthSigninCreateMutation();
  const [createIdMutation, { isLoading: createIdIsLoading, error: createIdError }] = useEmailsInvoiceCreateMutation();

  const onSave: SubmitHandler<{ emailId?: string }> = useCallback(
    ({ emailId }) => {
      if (emailId) {
        toggleEdit();
      }
    },
    [toggleEdit],
  );

  const handleCreateId = useCallback(async () => {
    try {
      const capsureEmail = `${createIdForm.getValues('emailId') as string}${EMAIL_DOMAIN}`;
      await createIdMutation({ invoiceEmailCreateRequest: { email: capsureEmail } });

      if (createIdError) {
        return snackbar.enqueueSnackbar(getErrorMessage(createIdError, API_ERROR_MSG_PATH), { variant: ERROR });
      }

      const loginResponse = await loginMutation({
        loginRequest: { email, password },
      }).unwrap();

      dispatch(api.util.resetApiState());
      dispatch(loginSuccess(loginResponse));
      dispatch(confirmSuccess());

      navigate(ROUTING.ROOT);
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  }, [createIdError, createIdForm, createIdMutation, dispatch, email, loginMutation, navigate, password, snackbar]);

  const isLoading = [createIdIsLoading, loginIsLoading].some(Boolean);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.createId')}</title>
      </Helmet>
      <Box display="flex" width="100%" justifyContent="center" alignItems="center" aria-label="confirm email page">
        <FormProvider {...createIdForm}>
          <Box component="form" height="930px" onSubmit={createIdForm.handleSubmit(onSave, err => console.log(err))}>
            <CreateIDForm loading={isLoading} handleCreate={handleCreateId} />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(CreateID);
