import styled from '@mui/system/styled';

const CollapseIcon = styled(props => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.4378 0L14.3604 5.13985L19.4378 10.2797L21 8.69825L17.4849 5.13985L21 1.58153L19.4378 0ZM19.8845 18V15.7635H0V18H19.8845ZM9.94223 10.172V7.93551H0V10.172H9.94223ZM9.94223 2.34419V0.107647H0V2.34419H9.94223Z"
      fill="white"
    />
  </svg>
))({});

export default CollapseIcon;
