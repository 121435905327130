import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const CreateIdContext = createContext<ReturnType<typeof useCreateIdContextValue> | null>(null);

const schema = yup.object().shape({
  emailId: yup.string(),
});

const useCreateIdContextValue = () => {
  const [editId, setEditId] = useState(false);

  const { state } = useLocation();
  const myEmail = state?.email as string;
  const emailPrefix = myEmail ? myEmail?.split('@')[0] : '';
  const createIdForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emailId: emailPrefix as string,
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const toggleEdit = useCallback(() => setEditId(!editId), [editId]);

  return {
    createIdForm,
    editId,
    toggleEdit,
    emailPrefix,
  };
};

export const useCreateIdContext = () => {
  const context = useContext(CreateIdContext);
  if (!context) throw new Error('useCreateIdContext must be inside CreateIdProvider');
  return context;
};

export const CreateIdProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useCreateIdContextValue();
  return <CreateIdContext.Provider value={value}>{children}</CreateIdContext.Provider>;
};
