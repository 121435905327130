import { Box, BoxProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledDocumentNameContainer = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '5px 5px 0px 0px',
  border: `1px solid ${theme.colors.background.docBorder}`,
  backgroundColor: 'transparent',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
  margin: 0,
  borderBottom: 'transparent',
}));

export const StyledDocumentBodyContainer = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  border: `1px solid ${theme.colors.background.docBorder}`,
  backgroundColor: 'transparent',
  margin: 0,
}));

export const StyledDocViewerWrapper = styled('div')`
  #pdf-controls {
    display: none;
  }

  #image-renderer {
    background-image: none;
  }

  #header-bar {
    min-height: 0;
  }

  div:nth-child(1) {
    height: 450px;
  }

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page {
    max-width: 100%;
    max-height: 415px;
    overflow: hidden;
  }

  .react-pdf__Page__canvas {
    max-width: 100% !important;
    height: auto !important;
    width: auto !important;
    max-height: 415px !important;
  }

  .react-pdf__Page__textContent.textLayer {
    display: none;
  }
`;
