import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTitle = styled(Typography)<TypographyProps>({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '32px',
  letterSpacing: '1px',
});

export const StyledBox = styled(Box)<BoxProps>({
  overflowWrap: 'break-word',
});
