import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledTextButton } from '@pages/InvoiceDetailsNew/components/InfoColumn/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledModal } from '../styled';

const UndoModal = NiceModal.create(
  ({ title, text, buttonText }: { title: string; text: string; buttonText: string }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="medium" onClose={handleCancelClick}>
        <Box padding="16px 24px">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" fontFamily="WFVisualSans" fontWeight={500}>
              {t(title)}
            </Typography>
            <IconButton onClick={handleCancelClick}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body1" fontWeight={400} mt={2}>
            {text}
          </Typography>

          <Box display="flex" justifyContent="end" mt={2}>
            <StyledTextButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="text"
              color="primary"
              onClick={handleConfirmClick}
            >
              {buttonText}
            </StyledTextButton>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const UndoModalId = 'UndoModal';

NiceModal.register(UndoModalId, UndoModal);
