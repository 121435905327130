import { MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import * as yup from 'yup';

export const updateInvoiceSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .min(3, 'Description needs to be at least 3 characters long and can’t be all special characters.')
    .matches(/[A-Za-z0-9]/, 'Description needs to be at least 3 characters long and can’t be all special characters.')
    .max(
      MAX_DESCRIPTION_CHARACTERS,
      ({ value }) => `${value.length - MAX_DESCRIPTION_CHARACTERS} characters over the 40 character limit.`,
    ),
  total_amount: yup.string().nullable(),
  service_exact_date: yup.string().nullable(),
  support_more_date: yup.boolean().nullable(), // checkbox
  service_start_date: yup.string().nullable(),
  service_end_date: yup.string().nullable(),
  vendor_name: yup
    .string()
    .nullable()
    .min(3, 'Description needs to be at least 3 characters long and can’t be all special characters.')
    .matches(/[A-Za-z0-9]/, 'Description needs to be at least 3 characters long and can’t be all special characters.')
    .max(
      MAX_DESCRIPTION_CHARACTERS,
      ({ value }) => `${value.length - MAX_DESCRIPTION_CHARACTERS} characters over the 40 character limit.`,
    ),
  invoice_date: yup.date(),
  due_date: yup.string().nullable(),
  updated_at: yup.string().nullable(),
  customer_name: yup.string().nullable(),
  customer_address: yup.string().nullable(),
  abn: yup.string().when('has_abn', (has_abn, schema) => has_abn[0]
      ? schema.required('ABN is required').min(11, 'ABN needs to be eleven numbers long.')
      : schema.nullable()),
  sub_total: yup.string().nullable(),
  total_tax: yup.string().nullable(),
  currency_symbol: yup.string().nullable(),
  currency: yup.string().nullable(),
  category: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .nullable(),
  missing_abn_reason: yup.string().nullable(),
  missing_category: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .nullable(),
  has_abn: yup.boolean().nullable(),
});

export const detailsSchema = yup.object().shape({
  missing_abn_reason: yup.string().nullable(),
  missing_category: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .nullable(),
});

export const paymentSchema = yup.object().shape({
  paid_date: yup.string().nullable(),
  paid_reference_number: yup.string().nullable(),
});

export const claimSchema = yup.object().shape({
  claimed_date: yup.string().nullable(),
  claim_id: yup.string().nullable().min(3, 'This field should have at least 3 digits'),
});
