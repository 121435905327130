import { FC, memo } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StyledAlert,
  StyledEditButton,
} from '@pages/InvoiceDetailsNew/components/EditableColumn/components/IssueBlock/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { UpdateSchema } from '@pages/InvoiceDetailsNew/schema/types';

import { Box, Typography } from '@mui/material';

interface ErrorMapping {
  description: Record<string, string>;
  total_amount: string;
  abn: string;
}

type ErrorKeys = 'description' | 'total_amount' | 'abn';

const errorMapping: ErrorMapping = {
  description: {
    min: 'dashboard.errorMessage.description.tooShort',
    max: 'dashboard.errorMessage.description.tooLong',
    matches: 'dashboard.errorMessage.description.tooShort',
  },
  total_amount: 'dashboard.errorMessage.totalAmount',
  abn: 'dashboard.errorMessage.abn',
};

const IssueBlock: FC<{ errors: FieldErrors<UpdateSchema> }> = ({ errors }) => {
  const { t } = useTranslation();

  const { toggleEdit, editInvoice, setEditInvoice } = useInvoiceDetailsNewContext();

  const getErrorMessage = (fieldName: ErrorKeys, error: any) => {
    if (typeof errorMapping[fieldName] === 'object') {
      return t((errorMapping[fieldName] as Record<string, string>)[error.type] || error.message);
    }
    return t(errorMapping[fieldName]);
  };
  return (
    <StyledAlert severity="error">
      <Box m={0} p={0} display="flex" justifyContent="space-between">
        <Box>
          <Typography>There is an issue with the details we captured</Typography>
          {Object.entries(errors).map(([fieldName, error]) => (
            <Typography key={fieldName} fontSize={14} fontWeight={400}>
              {getErrorMessage(fieldName as ErrorKeys, error)}
            </Typography>
          ))}
        </Box>
        <StyledEditButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="text"
          onClick={() => toggleEdit(editInvoice, setEditInvoice)}
        >
          {t('common.edit')}
        </StyledEditButton>
      </Box>
    </StyledAlert>
  );
};

export default memo(IssueBlock);
