import { FC } from 'react';
import { NAV } from '@providers/layouts/AppLayout/config';
import { StyledTextOverflowBox } from '@providers/layouts/AppLayout/styled';

import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const TrialSection: FC<{ expanded: boolean }> = ({ expanded }) => (
  <StyledTextOverflowBox
    display="flex"
    gap={2}
    width={expanded ? NAV.WIDTH - NAV.COLLAPSED_WIDTH : NAV.COLLAPSED_WIDTH}
    overflow="hidden"
  >
    <ScheduleTwoToneIcon color="primary" fontSize="large" />
    <Box>
      {/* ToDo adjust after api update */}
      <Typography fontSize={14} fontWeight={400}>
        Free trial ends in 7 days
      </Typography>
      <Typography fontSize={14} fontWeight={400}>
        10/10 invoices remaining
      </Typography>
    </Box>
  </StyledTextOverflowBox>
);

export default TrialSection;
