import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ROUTING } from '@constants/routing';

export const usePages = () => {
  const { t } = useTranslation();
  const { invoiceId = '1' } = useParams();

  return [
    {
      title: t('dashboard.navigation.invoices'),
      path: ROUTING.ROOT,
    },
    {
      title: t('dashboard.navigation.toDoList'),
      path: `/${ROUTING.TO_DO_LIST}`,
    },
    {
      title: t('dashboard.navigation.claimWizard'),
      path: `/${ROUTING.CLAIM_WIZARD}`,
    },
    {
      title: t('dashboard.navigation.uploadInvoice'),
      path: `/${ROUTING.INVOICE_UPLOAD}`,
    },
    {
      title: t('dashboard.navigation.invoiceDetails'),
      path: `/${ROUTING.INVOICE_DETAILS}/${invoiceId}`,
    },
  ];
};
