import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import NiceModal from '@ebay/nice-modal-react';
import {
  StyledDocumentBodyContainer,
  StyledDocumentNameContainer,
  StyledDocViewerWrapper,
} from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/DocumentPreview/styled';
import { PreviewDoc } from '@pages/InvoiceDetailsNew/components/PreviewColumn/types';
import { PreviewModalId } from '@pages/InvoiceDetailsNew/modals/PreviewModal';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box, Typography } from '@mui/material';
import FloatingViewButton from '@components/FloatingViewButton';

const DocumentPreview: FC<PreviewDoc> = ({ blob, fileName }) => {
  const { t } = useTranslation();
  const docs = [{ blob: blob as Blob, fileName: fileName as string }];

  return (
    <Box m={0} p={0} position="relative">
      <StyledDocumentNameContainer p={3.5}>
        <Typography fontWeight={400} fontSize={14}>
          {fileName}
        </Typography>
      </StyledDocumentNameContainer>
      <StyledDocumentBodyContainer p={1}>
        <StyledDocViewerWrapper>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs.map(file => ({
              uri: window.URL.createObjectURL(file.blob),
              fileName: file.fileName,
            }))}
            config={{
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        </StyledDocViewerWrapper>
      </StyledDocumentBodyContainer>
      <Box position="absolute" bottom={16} right={16}>
        <FloatingViewButton
          size="small"
          onClick={async e => {
            e.stopPropagation();
            await NiceModal.show(PreviewModalId, { docs });
          }}
          icon={RemoveRedEyeIcon}
          locale={t('common.view')}
        />
      </Box>
    </Box>
  );
};

export default memo(DocumentPreview);
