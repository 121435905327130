import styled from '@mui/system/styled';

const CheckCircleInvalid = styled(props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.3"
      d="M9.99992 16.6667C13.6818 16.6667 16.6666 13.6819 16.6666 10C16.6666 6.31814 13.6818 3.33337 9.99992 3.33337C6.31802 3.33337 3.33325 6.31814 3.33325 10C3.33325 13.6819 6.31802 16.6667 9.99992 16.6667Z"
      fill="black"
      fillOpacity="0.56"
    />
    <path
      d="M9.99996 1.66669C5.39163 1.66669 1.66663 5.39169 1.66663 10C1.66663 14.6084 5.39163 18.3334 9.99996 18.3334C14.6083 18.3334 18.3333 14.6084 18.3333 10C18.3333 5.39169 14.6083 1.66669 9.99996 1.66669ZM9.99996 16.6667C6.31663 16.6667 3.33329 13.6834 3.33329 10C3.33329 6.31669 6.31663 3.33335 9.99996 3.33335C13.6833 3.33335 16.6666 6.31669 16.6666 10C16.6666 13.6834 13.6833 16.6667 9.99996 16.6667Z"
      fill="black"
    />
  </svg>
))({});

export default CheckCircleInvalid;
