import { FC, memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePasswordValidation } from '@hooks/usePasswordValidation';
import { useShrink } from '@hooks/useShrink';
import ResetPasswordChanged from '@pages/ResetPassword/components/ResetPasswordChanged';
import { StyledTypography } from '@pages/ResetPassword/components/styled';
import { StyledBox, StyledLoadingButton, StyledPaper } from '@pages/styled';

import { Box, Typography } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';
import PasswordValidationList from '@components/PasswordValidationList';

const ResetPasswordForm: FC<{ loading: boolean; requestSent: boolean }> = ({ loading, requestSent }) => {
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { passwordErrors, validatePassword } = usePasswordValidation();
  const passwordShrink = useShrink();
  const repeatPasswordShrink = useShrink();

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column" gap={6}>
      <StyledTypography variant="h3">{t('common.resetPasswordTitle')}</StyledTypography>
      <StyledPaper elevation={5}>
        <StyledBox>
          {requestSent && <ResetPasswordChanged />}
          {!requestSent && (
            <>
              <Typography variant="h6" mb={4}>
                {t('common.resetPasswordSubtitle')}
              </Typography>
              <Box display="flex" flexDirection="column" gap={3}>
                <Controller
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState }) => (
                    <InputPassword
                      fullWidth
                      variant="outlined"
                      label={t('common.inputs.newPassword')}
                      inputProps={{
                        'aria-required': 'true',
                        'aria-label': 'password input field',
                      }}
                      InputLabelProps={{
                        shrink: passwordShrink.shrink,
                      }}
                      autoComplete="off"
                      error={Boolean(fieldState.error)}
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      onChange={e => {
                        validatePassword(e.target.value);
                        onChange(e);

                        if (!isTouched) {
                          setIsTouched(true);
                        }
                      }}
                      {...field}
                      onFocus={() => passwordShrink.setShrink(true)}
                      onBlur={e => {
                        !e.target.value && passwordShrink.setShrink(false);
                      }}
                    />
                  )}
                  name="password"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState }) => (
                    <InputPassword
                      fullWidth
                      variant="outlined"
                      label={t('common.inputs.repeatNewPassword')}
                      inputProps={{
                        'aria-required': 'true',
                        'aria-label': 'repeat password input field',
                      }}
                      InputLabelProps={{
                        shrink: repeatPasswordShrink.shrink,
                      }}
                      autoComplete="off"
                      error={Boolean(fieldState.error)}
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      onChange={e => {
                        validatePassword(e.target.value);
                        onChange(e);

                        if (!isTouched) {
                          setIsTouched(true);
                        }
                      }}
                      {...field}
                      onFocus={() => repeatPasswordShrink.setShrink(true)}
                      onBlur={e => {
                        !e.target.value && repeatPasswordShrink.setShrink(false);
                      }}
                    />
                  )}
                  name="repeatPassword"
                />
              </Box>
              <PasswordValidationList errors={passwordErrors} isTouched={isTouched} sx={{ my: 5 }} />
              <StyledLoadingButton
                fullWidth
                variant="contained"
                color="primary"
                loading={loading}
                type="submit"
                aria-label="submit button"
              >
                {t('auth.updatePassword')}
              </StyledLoadingButton>
            </>
          )}
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(ResetPasswordForm);
