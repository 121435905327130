import { Chip, ChipProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledChip = styled(Chip)<ChipProps & { theme?: Theme; isModified?: boolean }>(
  ({ theme, isModified }) => ({
    color: isModified ? theme.colors.primary.dark : theme.colors.black,
    border: '1px solid',
    borderColor: isModified ? theme.colors.primary.dark : theme.colors.background.secondaryDarkBorder,
    backgroundColor: isModified ? theme.colors.background.primaryBackground : theme.colors.secondary.light,
    borderRadius: '100px',
    width: 'auto',
    padding: '8px 14px',
    fontFamily: 'Poppins',
    letterSpacing: '0.16px',
  }),
);
