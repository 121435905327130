import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEmailsInvoiceCheckUniqueCreateMutation } from '@api/api';
import { EMAIL_DOMAIN } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { StyledBoxWrapper, StyledCancelBtn } from '@pages/CreateID/components/styled';
import { useCreateIdContext } from '@pages/CreateID/context';
import { StyledCircularProgress } from '@pages/Login/components/styled';
import { getErrorMessage } from '@utils/getMessage';
import debounce from 'lodash-es/debounce';
import { useSnackbar } from 'notistack';

import { Box, TextField, Typography } from '@mui/material';
import { ErrorIcon, SuccessIcon } from '@components/icons';
import LoadingButton from '@components/LoadingButton';

const debouncedCheckAvailability = debounce(checkAvailability => {
  checkAvailability();
}, 300);

const EditId: FC = () => {
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const { t } = useTranslation();
  const { control, watch, getFieldState, setError, clearErrors, resetField } = useFormContext();
  const { toggleEdit, emailPrefix } = useCreateIdContext();
  const snackbar = useSnackbar();

  const idField = watch('emailId');
  const isDisabled = !idField || idField?.length < 1;
  const isError = getFieldState('emailId').error;
  const emailToCheck = `${idField}${EMAIL_DOMAIN}`;

  const [checkEmailMutation, { isLoading: isLoadingCheck }] = useEmailsInvoiceCheckUniqueCreateMutation();

  const handleCheckEmail = useCallback(async () => {
    if (isDisabled) {
      setIsAvailable(null);
      return;
    }

    try {
      const res = await checkEmailMutation({ inputRequest: { email: emailToCheck } });
      if (!res?.data?.is_available) {
        setError('emailId', {
          type: 'validate',
          message: t('common.notAvailable'),
        });
        setIsAvailable(false);
      } else {
        clearErrors('emailId');
        setIsAvailable(true);
      }
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH));
    }
  }, [checkEmailMutation, emailToCheck, isDisabled, setError, clearErrors, snackbar, t]);

  const handleCancel = () => {
    resetField('emailId', { defaultValue: emailPrefix });
    toggleEdit();
  };

  useEffect(() => {
    if (idField && !isDisabled) {
      debouncedCheckAvailability(handleCheckEmail);
    } else {
      setIsAvailable(null);
    }
  }, [idField, isDisabled, handleCheckEmail]);

  return (
    <Box>
      <StyledBoxWrapper display="flex" alignItems="center" gap={1}>
        <Controller
          name="emailId"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              label={t('auth.enterCustomId')}
              variant="outlined"
              type="text"
              sx={{ width: 220 }}
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'custom id input field',
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Typography variant="h6" fontWeight={500}>
          {EMAIL_DOMAIN}
        </Typography>
      </StyledBoxWrapper>
      {isLoadingCheck && !isDisabled && (
        <Box mt={1}>
          <StyledCircularProgress size={16} />
        </Box>
      )}
      {isAvailable && !isLoadingCheck && !isDisabled && (
        <Box mt={1} alignItems="center" color="green" display="flex" gap={1}>
          <SuccessIcon />
          {t('common.available')}
        </Box>
      )}
      {isError && !isLoadingCheck && (
        <Box mt={1} alignItems="center" color="red" display="flex" gap={1}>
          <ErrorIcon />
          {t('common.notAvailable')}
        </Box>
      )}
      <Box display="flex" gap={2} mt={6}>
        <StyledCancelBtn variant="outlined" onClick={handleCancel}>
          {t('common.cancel')}
        </StyledCancelBtn>
        <LoadingButton
          variant="contained"
          loading={false}
          disabled={!isAvailable}
          type="submit"
          sx={{ minWidth: '115px', fontWeight: 500 }}
        >
          {t('common.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default memo(EditId);
