import { FC, memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthPasswordResetCreateMutation } from '@api/api';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box } from '@mui/material';

import ForgotPasswordForm from './components/ForgotPasswordForm';

export interface ForgotPasswordDto {
  email: string;
  redirectURL: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  redirectURL: yup.string().required(),
});

const RESET_PASSWORD_URL = `${window.location.origin}/${ROUTING.RESET_PASSWORD}`;

const ForgotPassword: FC = () => {
  const [requestSent, setRequestSent] = useState(false);
  const { t } = useTranslation();

  const form = useForm<ForgotPasswordDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      redirectURL: RESET_PASSWORD_URL,
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { setValue } = form;
  const [forgetPasswordMutation, { isLoading: isForgetPasswordLoading }] = useAuthPasswordResetCreateMutation();

  const onConfirm: SubmitHandler<ForgotPasswordDto> = useCallback(
    async ({ email, redirectURL }) => {
      try {
        const res = await forgetPasswordMutation({ passwordResetRequest: { email, frontend_url: redirectURL } });
        // @ts-ignore
        if (res?.error?.status === 404) {
          return form.setError('email', {
            type: 'validate',
            message: t('auth.emailNotFound'),
          });
        }
        setRequestSent(true);
      } catch (err) {
        console.log(err);
      } finally {
        setValue('email', '');
      }
    },
    [forgetPasswordMutation, form, setValue, t],
  );

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.forgetPassword')}</title>
      </Helmet>
      <Box display="flex" width="100%" justifyContent="center" alignItems="center" aria-label="forgot password page">
        <FormProvider {...form}>
          <Box component="form" height="540px" onSubmit={form.handleSubmit(onConfirm)}>
            <ForgotPasswordForm loading={isForgetPasswordLoading} requestSent={requestSent} />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(ForgotPassword);
