import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Detail from '@pages/InvoiceDetailsNew/components/EditableColumn/components/Detail';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import { Box, Grid } from '@mui/material';

const InvoiceDetailsSection: FC = () => {
  const { t } = useTranslation();
  const { serviceDescription, invoiceData, supportCategory, providerAbnNumber, isReason, invoiceForm } =
    useInvoiceDetailsNewContext();

  const { errors: invoiceFormErrors } = invoiceForm.formState;

  const serviceExactDay =
    invoiceData?.service_exact_date && convertIsoToReadable(invoiceData.service_exact_date, { fullYear: true });
  const serviceStartDay =
    !serviceExactDay &&
    invoiceData?.service_start_date &&
    convertIsoToReadable(invoiceData.service_start_date, { fullYear: true });
  const serviceEndDay =
    !serviceExactDay &&
    invoiceData?.service_end_date &&
    convertIsoToReadable(invoiceData.service_end_date, { fullYear: true });

  return (
    <Box display="flex" flexDirection="column" gap={3} width="100%">
      {serviceDescription && (
        <Detail
          title={t('dashboard.invoiceDetails.supportDescription')}
          value={serviceDescription}
          hasError={!!invoiceFormErrors.description}
        />
      )}
      <Grid container gap="20px">
        {invoiceData?.total_amount && (
          <Grid item xs={12} md={3}>
            <Detail
              title={t('dashboard.invoiceDetails.amountDescription')}
              value={`${invoiceData?.currency_symbol}${formatCurrency(invoiceData.total_amount)}`}
            />
          </Grid>
        )}
        <Grid item xs={12} md={8} display="flex" justifyContent="space-between">
          {!serviceStartDay && !serviceEndDay && (
            <Detail
              title={t('dashboard.invoiceDetails.supportDateDescription')}
              value={serviceExactDay || t('common.none')}
            />
          )}
          {serviceStartDay && (
            <Detail title={t('dashboard.invoiceDetails.supportDateStartDescription')} value={serviceStartDay} />
          )}
          {serviceEndDay && (
            <Detail title={t('dashboard.invoiceDetails.supportDateEndDescription')} value={serviceEndDay} />
          )}
        </Grid>
      </Grid>

      <Grid container display="flex" gap={3}>
        <Grid item xs={12} md={3}>
          <Detail title={t('dashboard.invoiceDetails.abnDescription')} value={providerAbnNumber} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Detail
            title={t('dashboard.invoiceDetails.providerDescription')}
            value={invoiceData?.vendor_name}
            hasError={!!invoiceFormErrors.vendor_name}
          />
        </Grid>
      </Grid>

      {supportCategory && (
        <Detail title={t('dashboard.invoiceDetails.supportCategoryDescription')} value={supportCategory?.name} />
      )}
      {isReason && <Detail title={t('dashboard.abnMissingReason')} value={invoiceData?.reason.name} />}
    </Box>
  );
};

export default memo(InvoiceDetailsSection);
