import { FC, memo, useEffect } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import ClaimedDetails from '@pages/InvoiceDetailsNew/components/EditableColumn/components/ClaimedDetails';
import EditInvoiceDetailsForm from '@pages/InvoiceDetailsNew/components/EditableColumn/components/EditInvoiceDetailsForm';
import InvoiceDetailsSection from '@pages/InvoiceDetailsNew/components/EditableColumn/components/InvoiceDetailsSection';
import IssueBlock from '@pages/InvoiceDetailsNew/components/EditableColumn/components/IssueBlock';
import MissingDetailsForm from '@pages/InvoiceDetailsNew/components/EditableColumn/components/MissingDetailsForm';
import PaymentDetails from '@pages/InvoiceDetailsNew/components/EditableColumn/components/PaymentDetails';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { ClaimSchema, DetailsSchema, PaymentSchema, UpdateSchema } from '@pages/InvoiceDetailsNew/schema/types';
import { formatDate } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';

import { StyledEditSectionButton } from './styled';

const EditableColumn: FC = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const {
    editInvoice,
    toggleEdit,
    handleResetForm,
    supportCategory,
    invoiceData,
    detailsForm,
    isPaid,
    isClaimed,
    paymentDetailsForm,
    claimDetailsForm,
    setEditInvoice,
    setEditClaim,
    setEditPayment,
    invoiceId,
    invoiceForm,
  } = useInvoiceDetailsNewContext();

  const { errors: invoiceFormErrors } = invoiceForm.formState;
  const showMissingDetailsForm = !supportCategory || (!invoiceData?.abn && !invoiceData?.reason.id);
  const showAlertBlock = !editInvoice && Object.keys(invoiceFormErrors).length > 0;

  const [invoicePartialUpdateMutation, { isLoading: isLoadingPartialUpdate }] =
    useApiInvoicesUpdatePartialUpdateMutation();

  async function updateInvoice(payload: Record<string, any>) {
    await invoicePartialUpdateMutation({
      id: +invoiceId,
      patchedInvoiceUpdateRequest: payload,
    });
  }

  const handleSubmitMissingDetails: SubmitHandler<DetailsSchema> = async ({ missing_category, missing_reason }) => {
    try {
      const payload = {
        ...(missing_category && { category_id: missing_category.id }),
        ...(missing_reason && { reason_id: missing_reason.id }),
      };

      await updateInvoice(payload);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH));
    }
  };

  const handleSubmitPaymentDetails: SubmitHandler<PaymentSchema> = async ({ paid_date, paid_reference_number }) => {
    try {
      setEditPayment(false);
      const payload = {
        ...(paid_date && { paid_date: new Date(paid_date).toISOString() }),
        ...(paid_reference_number && { paid_reference_number }),
        ...((paid_date || paid_reference_number) && !invoiceData?.is_paid && { is_paid: true }),
      };
      await updateInvoice(payload);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH));
    }
  };

  const handleSubmitClaimDetails: SubmitHandler<ClaimSchema> = async ({ claimed_date, claimed_reference_number }) => {
    try {
      setEditClaim(false);
      const payload = {
        ...(claimed_date && { claimed_date: new Date(claimed_date).toISOString() }),
        ...(claimed_reference_number && { claimed_reference_number }),
        ...((claimed_date || claimed_reference_number) && !invoiceData?.is_claimed && { is_claimed: true }),
      };
      await updateInvoice(payload);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH));
    }
  };

  const handleSubmitUpdate: SubmitHandler<UpdateSchema> = async ({
    description,
    total_amount,
    support_more_date,
    service_start_date,
    service_end_date,
    service_exact_date,
    vendor_name,
    has_abn,
    abn,
    category,
    missing_reason,
  }) => {
    try {
      const payload = {
        ...(description && { description }),
        ...(total_amount && { total_amount: total_amount.replace(/,/g, '') }),
        ...(support_more_date &&
          service_start_date &&
          service_end_date && {
            service_start_date: formatDate(new Date(service_start_date)),
            service_end_date: formatDate(new Date(service_end_date)),
            service_exact_date: null,
          }),
        ...(!support_more_date &&
          service_exact_date && {
            service_exact_date: formatDate(new Date(service_exact_date)),
            service_start_date: null,
            service_end_date: null,
          }),
        ...(vendor_name && { vendor_name }), // provider
        ...(category && { category_id: category.id }),
        ...(missing_reason && { reason: missing_reason.id }),
        ...(has_abn && abn && { abn, reason: null }),
      };
      await updateInvoice(payload);
      toggleEdit(editInvoice, setEditInvoice);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  useEffect(() => {
    invoiceForm.trigger();
  }, [editInvoice, invoiceForm]);

  return (
    <Box display="flex" flexDirection="column" gap={3} height="100%">
      <Typography variant="h6" fontWeight={500} fontFamily="WFVisualSans">
        {t('dashboard.navigation.invoiceDetails')}
      </Typography>
      {!editInvoice && (
        <StyledEditSectionButton
          variant="outlined"
          size="small"
          onClick={() => {
            toggleEdit(editInvoice, setEditInvoice);
            handleResetForm();
          }}
          endIcon={<EditIcon />}
        >
          {t('common.editInvoice')}
        </StyledEditSectionButton>
      )}
      {showAlertBlock && <IssueBlock errors={invoiceFormErrors} />}
      {!editInvoice && <InvoiceDetailsSection />}
      {editInvoice && (
        <FormProvider {...invoiceForm}>
          <form onSubmit={invoiceForm.handleSubmit(handleSubmitUpdate, err => console.log(err))}>
            <EditInvoiceDetailsForm loading={isLoadingPartialUpdate} />
          </form>
        </FormProvider>
      )}
      {showMissingDetailsForm && !editInvoice && (
        <FormProvider {...detailsForm}>
          <form onSubmit={detailsForm.handleSubmit(handleSubmitMissingDetails)}>
            <MissingDetailsForm loading={isLoadingPartialUpdate} />
          </form>
        </FormProvider>
      )}

      {!editInvoice && isPaid && (
        <FormProvider {...paymentDetailsForm}>
          <form onSubmit={paymentDetailsForm.handleSubmit(handleSubmitPaymentDetails)}>
            <PaymentDetails loading={isLoadingPartialUpdate} />
          </form>
        </FormProvider>
      )}
      {!editInvoice && isClaimed && (
        <FormProvider {...claimDetailsForm}>
          <form onSubmit={claimDetailsForm.handleSubmit(handleSubmitClaimDetails)}>
            <ClaimedDetails loading={isLoadingPartialUpdate} />
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

export default memo(EditableColumn);
