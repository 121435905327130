import { useCallback, useState } from 'react';
import { ApiInvoicesDownloadRetrieveApiArg } from '@api/api';
import { ERROR, USER_ACCESS_TOKEN } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { getErrorMessage } from '@utils/getMessage';
import { getToken } from '@utils/manipulateStorage';
import { useSnackbar } from 'notistack';

const baseUrl = import.meta.env.VITE_APP_API_URL;

const getFilename = (response: Response) => {
  const contentDisposition = response.headers.get('content-disposition');
  return contentDisposition?.match(/filename="(.+?)"/)?.[1] || 'invoice';
};

const getFiletype = (response: Response) => response.headers.get('content-type') || '';
const getFileExtension = (mimeType: string) => mimeType.split('/').pop() || '';

const useDownloadFile = () => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const snackbar = useSnackbar();
  const token = getToken(USER_ACCESS_TOKEN);
  const [controller, setController] = useState<AbortController | null>(null);

  const fetchDocument = useCallback(
    async (payload: ApiInvoicesDownloadRetrieveApiArg) => {
      const currentController = new AbortController();
      setController(currentController);

      try {
        const response = await fetch(`${baseUrl}/api/invoices/${payload.id}/download`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal: currentController.signal,
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          console.log('Fetch aborted');
        } else {
          throw err;
        }
      } finally {
        setController(null);
      }
    },
    [token],
  );

  const downloadFile = async (payload: ApiInvoicesDownloadRetrieveApiArg) => {
    setLoadingDownload(true);
    setError(null);

    try {
      const response = await fetchDocument(payload);
      if (response) {
        // console.log('Response status:', response.status);
        // console.log('Response headers:', [...response.headers.entries()]);
        const blob = await response.blob();
        const filename = getFilename(response);
        // console.log('Filename:', filename);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        setError(err as Error);
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    } finally {
      setLoadingDownload(false);
    }
  };

  const fetchExcelFiles = useCallback(async () => {
    const excelController = new AbortController();
    setController(excelController);

    try {
      return await fetch(`${baseUrl}/api/invoices/export/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        signal: excelController.signal,
      });
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        console.log('Fetch aborted');
      } else {
        throw err;
      }
    } finally {
      setController(null);
    }
  }, [token]);

  const exportExcelFiles = async () => {
    setLoadingExcel(true);
    setError(null);

    try {
      const response = await fetchExcelFiles();
      if (response) {
        const blob = await response.blob();
        const filename = getFilename(response);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        setError(err as Error);
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    } finally {
      setLoadingExcel(false);
    }
  };

  const downloadPreview = async (payload: ApiInvoicesDownloadRetrieveApiArg) => {
    setLoadingPreview(true);
    setError(null);

    try {
      const response = await fetchDocument(payload);
      if (response) {
        const blob = await response.blob();
        const filename = getFilename(response);
        const filetype = getFiletype(response);
        if (filename) {
          setFileName(filename);
        }
        if (filetype) {
          const extension = getFileExtension(filetype);
          setFileType(extension);
        }
        if (blob) {
          setFileBlob(blob);
        }

        return { blob, filename, filetype };
      }
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        setError(err as Error);
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
      return null;
    } finally {
      setLoadingPreview(false);
    }
  };

  const cancelRequest = () => {
    if (controller) {
      controller.abort();
      setController(null);
    }
  };

  return {
    downloadFile,
    downloadPreview,
    isLoadingDownload: loadingDownload,
    isLoadingPreview: loadingPreview,
    isLoadingExcel: loadingExcel,
    // isLoadingExcel: isLoadingExport,
    isError: error,
    cancelRequest,
    exportExcelFiles,
    fileName,
    fileBlob,
    fileType,
  };
};

export default useDownloadFile;
