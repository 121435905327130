import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useAnimateAutofill } from '@hooks/useAnimateAutofill';
import { useShrink } from '@hooks/useShrink';
import { LoginSchema } from '@pages/Login/types';
import { StyledBox, StyledLoadingButton, StyledPaper } from '@pages/styled';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, Checkbox, FormControlLabel, FormControlLabelProps, InputAdornment } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';

import { StyledLink, StyledTextField, StyledTypography } from './styled';

const componentsProps: FormControlLabelProps['componentsProps'] = {
  typography: {
    variant: 'body2',
    sx: theme => ({
      display: 'flex',
      columnGap: '4px',
      color: theme.colors.text,
      textIndent: 4,
    }),
  },
};

const LoginForm: FC<{ loading: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LoginSchema>();
  const emailShrink = useShrink();
  const passwordShrink = useShrink();
  const { makeAnimationStartHandler } = useAnimateAutofill();

  return (
    <>
      <StyledTypography variant="h3" mb="40px">
        {t('auth.loginText')}
      </StyledTypography>
      <StyledPaper elevation={8}>
        <StyledBox>
          <Box mb={2} display="flex" flexDirection="column" gap={2}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label={t('common.inputs.email')}
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'email input field',
                    onAnimationStart: makeAnimationStartHandler(emailShrink.setShrink),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: emailShrink.shrink,
                  }}
                  type="email"
                  autoComplete="on"
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                  onFocus={() => emailShrink.setShrink(true)}
                  onBlur={e => {
                    !e.target.value && emailShrink.setShrink(false);
                  }}
                />
              )}
              name="email"
            />
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <InputPassword
                  fullWidth
                  variant="outlined"
                  label={t('common.inputs.password')}
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': 'password input field',
                    onAnimationStart: makeAnimationStartHandler(passwordShrink.setShrink),
                  }}
                  InputLabelProps={{
                    shrink: passwordShrink.shrink,
                  }}
                  autoComplete="on"
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                  onFocus={() => passwordShrink.setShrink(true)}
                  onBlur={e => {
                    !e.target.value && passwordShrink.setShrink(false);
                  }}
                />
              )}
              name="password"
            />
            <FormControlLabel
              componentsProps={componentsProps}
              sx={{ marginLeft: 0 }}
              control={
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={!!value} onChange={e => onChange(e.target.checked)} />
                  )}
                  name="rememberMe"
                />
              }
              label={t('auth.rememberMe')}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={3} mb={2} textAlign="center">
            <StyledLoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="submit button"
            >
              {t('common.helmetTitles.login')}
            </StyledLoadingButton>
            <StyledLink
              component={RouterLink}
              to={`/${ROUTING.FORGOT_PASSWORD}`}
              aria-label="redirect to forgot password page"
            >
              {t('common.forgotPassword')}
            </StyledLink>
          </Box>
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default memo(LoginForm);
