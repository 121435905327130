import { TextField, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledTextField = styled(TextField)<TextFieldProps>({
  margin: '10px 0',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(43px, 17px)',
  },
});

export const StyledTypography = styled(Typography)<TypographyProps & { theme?: Theme }>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));
