import { FC, memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthPasswordResetConfirmCreateMutation } from '@api/api';
import { ERROR, SUCCESS } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTokenQueryParam } from '@pages/ResetPassword/hooks/useTokenQueryParam';
import { useUidQueryParam } from '@pages/ResetPassword/hooks/useUidQueryParam';
import { ResetSchema } from '@pages/ResetPassword/types';
import { logoutSuccess } from '@store/authSlice';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { Box } from '@mui/material';

import ResetPasswordForm from './components/ResetPasswordForm';

const schema = yup.object().shape({
  password: yup.string().minMax(8, 25).containsDigit().containsUppercase().required(),
  repeatPassword: yup
    .string()
    .required('Please retype your password')
    .oneOf([yup.ref('password')], 'Your passwords do not match'),
});

const ResetPassword: FC = () => {
  const [requestSent, setRequestSent] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const { token } = useTokenQueryParam();
  const { uid } = useUidQueryParam();

  const form = useForm<ResetSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: undefined,
      repeatPassword: undefined,
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const { setValue } = form;

  const [resetPasswordMutation, { isLoading: resetPasswordIsLoading, error }] =
    useAuthPasswordResetConfirmCreateMutation();

  const onResetPassword: SubmitHandler<{
    password: string;
    repeatPassword: string;
  }> = async ({ password, repeatPassword }) => {
    try {
      await resetPasswordMutation({
        passwordResetConfirmRequest: { new_password1: password, new_password2: repeatPassword, token, uid },
      });

      if (error) {
        snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
      } else {
        setRequestSent(true);
      }
      snackbar.enqueueSnackbar(t('auth.passwordChanged'), { variant: SUCCESS });
      dispatch(logoutSuccess());
      navigate(`/${ROUTING.LOGIN}`, { replace: true });
    } finally {
      setValue('password', '');
      setValue('repeatPassword', '');
      setRequestSent(false);
    }
  };

  useEffect(() => {
    if (token.length < 1) {
      navigate(`/${ROUTING.LOGIN}`, { replace: true });
      snackbar.enqueueSnackbar(t('auth.noOtp'), { variant: ERROR });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.resetPassword')}</title>
      </Helmet>
      <Box display="flex" width="100%" justifyContent="center" alignItems="center" aria-label="reset password page">
        <FormProvider {...form}>
          <Box component="form" height="560px" onSubmit={form.handleSubmit(onResetPassword, err => console.log(err))}>
            <ResetPasswordForm loading={resetPasswordIsLoading} requestSent={requestSent} />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(ResetPassword);
