import { FC, memo } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';

import { StyledBox, StyledTitle } from '../styled';

const CredentialDetails: FC<{ title: string; value?: string | null; hasError?: boolean }> = ({
  title,
  value,
  hasError,
}) => (
  <StyledBox display="flex" flexDirection="column" gap={0.5}>
    <Box display="flex" p={0} m={0} alignItems="center" gap={0.5}>
      <StyledTitle variant="subtitle2" hasError={hasError}>
        {title}
      </StyledTitle>
      {hasError ? <ErrorOutlineIcon color="error" /> : ''}
    </Box>
    <Typography variant="subtitle1" fontWeight={400} lineHeight="28px" letterSpacing="0.15px">
      {value || ''}
    </Typography>
  </StyledBox>
);

export default memo(CredentialDetails);
