import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import LoadingButton from '@components/LoadingButton';

export const StyledDocViewerWrapper = styled('div')`
  #pdf-controls {
    display: none;
  }
  #image-renderer {
    background-image: none;
  }
`;

export const StyledControlsBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  padding: '8px 10px',
  backgroundColor: theme.colors.background.secondaryBackground,
}));

export const StyledFiltersButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
});

export const StyledTextField = styled(TextField)<TextFieldProps>({
  margin: '10px 0',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(43px, 17px)',
  },
});

export const StyledDataGrid = styled(DataGrid)<DataGridProps & { theme?: Theme }>(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-cell': {
    padding: '10px 0',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root': {
    '--DataGrid-rowBorderColor': 'transparent',
    height: '100%',
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'transparent',
    justifyContent: 'start',
  },
  '& .MuiDataGrid-row': {
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '5px',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'block',
      pointerEvents: 'none',
      borderRadius: '5px',
      zIndex: 1,
    },
  },
  '& .MuiDataGrid-cell--withRenderer': {
    border: 'transparent',
  },
  '&.MuiDataGrid-root .MuiDataGrid-withBorderColor': {
    border: 'transparent',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: '0',
    letterSpacing: '2px',
  },
  '& .capsure-row--is_claimed::after': {
    border: `1.5px solid ${theme.colors.primary.main}`,
  },
  '& .capsure-row--not-claimed::after': {
    border: `1.5px solid ${theme.colors.background.secondaryDarkBorder}`,
  },
  '.MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    marginLeft: '2px',
    width: 'auto !important',
  },
  '.MuiDataGrid-sortIcon': {
    opacity: 'inherit !important',
  },
  '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover': {
    backgroundColor: 'inherit !important',
  },
  '& .MuiDataGrid-row.capsure-row--is_claimed': {
    backgroundColor: `${theme.colors.background.secondaryBackground} !important`,
  },
  '& .MuiDataGrid-row.capsure-row--not-claimed': {
    backgroundColor: `${theme.colors.background.secondaryDarkBackground} !important`,
  },
}));

export const StyledClaimTypography = styled(Typography)<TypographyProps & { theme?: Theme; claimed?: boolean }>(
  ({ theme, claimed = false }) => ({
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '2px',
    color: claimed ? theme.colors.primary.main : theme.colors.warning.main,
  }),
);

export const StyledLoadingButton = styled(LoadingButton)<{ theme?: Theme }>(({ theme }) => ({
  '& .MuiCircularProgress-root': {
    color: theme.colors.primary.main,
  },
  boxShadow: 'none',
}));
