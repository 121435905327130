import { FC, memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@constants/auth';
import { usePasswordValidation } from '@hooks/usePasswordValidation';
import { useShrink } from '@hooks/useShrink';
import { SignupSchema } from '@pages/Signup/types';
import { StyledBox, StyledLoadingButton, StyledPaper, StyledTextField } from '@pages/styled';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';
import PasswordValidationList from '@components/PasswordValidationList';

import { StyledLink, StyledPolicyTypography, StyledTitle } from './styled';

const SignUpForm: FC<{ loading: boolean }> = ({ loading }) => {
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation();
  const { control } = useFormContext<SignupSchema>();
  const { passwordErrors, validatePassword } = usePasswordValidation();
  const passwordShrink = useShrink();

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column">
      <StyledTitle variant="h3" mb={3} maxWidth={400}>
        {t('auth.signupText')}
      </StyledTitle>
      <Typography variant="h5" mb={4} fontFamily="WFVisualSans">
        {t('auth.signupSubtitle')}
      </Typography>
      <StyledPaper elevation={5}>
        <StyledBox gap={3}>
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('common.inputs.firstName')}
                type="text"
                autoComplete="off"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'firstname input field',
                }}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
            name="firstname"
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('common.inputs.lastName')}
                type="text"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'lastname input field',
                }}
                autoComplete="off"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
            name="lastname"
          />
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <StyledTextField
                fullWidth
                variant="outlined"
                label={t('common.inputs.email')}
                type="email"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'email input field',
                }}
                autoComplete="off"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
            name="email"
          />
          <Controller
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <InputPassword
                fullWidth
                variant="outlined"
                label={t('common.inputs.choosePassword')}
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'password input field',
                }}
                InputLabelProps={{
                  shrink: passwordShrink.shrink,
                }}
                autoComplete="off"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                onChange={e => {
                  validatePassword(e.target.value);
                  onChange(e);

                  if (!isTouched) {
                    setIsTouched(true);
                  }
                }}
                {...field}
                onFocus={() => passwordShrink.setShrink(true)}
                onBlur={e => {
                  !e.target.value && passwordShrink.setShrink(false);
                }}
              />
            )}
            name="password"
          />

          <PasswordValidationList errors={passwordErrors} isTouched={isTouched} />

          <StyledPolicyTypography variant="body1" mb={2}>
            <Trans
              i18nKey="common.privacyPolicyText"
              components={{
                termsOfService: <StyledLink href={TERMS_OF_SERVICE_URL} target="_blank" />,
                privacyPolicy: <StyledLink href={PRIVACY_POLICY_URL} target="_blank" />,
                icon: <OpenInNewIcon color="primary" style={{ marginBottom: '-5px' }} />,
              }}
              values={{
                terms: t('common.documentLinks.termsOfService'),
                policy: t('common.documentLinks.privacyPolicy'),
              }}
            />
          </StyledPolicyTypography>

          <Box width="100%">
            <StyledLoadingButton
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              aria-label="submit button"
            >
              {t('auth.continue')}
            </StyledLoadingButton>
          </Box>
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(SignUpForm);
