import { ROUTING } from '@constants/routing';

import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';

const navConfig = [
  {
    title: 'Invoices & Receipts',
    path: ROUTING.ROOT,
    icon: <ReceiptTwoToneIcon color="primary" />,
  },
  {
    title: 'To-do list',
    path: ROUTING.TO_DO_LIST,
    icon: <InventoryTwoToneIcon color="primary" />,
  },
  {
    title: 'Claim Wizard',
    path: ROUTING.CLAIM_WIZARD,
    icon: <AutoFixHighTwoToneIcon color="primary" />,
  },
];

export default navConfig;
