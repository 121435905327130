import { FC, memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PAYMENT_DATE_FIELD, PAYMENT_REFERENCE_NUMBER } from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';

import EventIcon from '@mui/icons-material/Event';
import { Box, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@components/LoadingButton';
import { theme } from '@components/theme';

const PaymentDetailsEdit: FC<{ loading?: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { control, setError, watch, trigger } = useFormContext();
  const { referenceNumber, paymentDate, setEditPayment } = useInvoiceDetailsNewContext();

  const numField = watch(PAYMENT_REFERENCE_NUMBER);

  const handleCancel = () => setEditPayment(false);

  useEffect(() => {
    if (numField?.length < 3) {
      setError(PAYMENT_REFERENCE_NUMBER, {
        type: 'manual',
        message: 'This field should have at least 3 digits',
      });
      trigger(PAYMENT_REFERENCE_NUMBER);
    }
  }, [numField?.length, setError, trigger]);

  return (
    <Box display="flex" flexDirection="column" maxWidth="320px" gap={3}>
      {paymentDate && (
        <Controller
          control={control}
          name={PAYMENT_DATE_FIELD}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              value={new Date(field.value)}
              label={t('dashboard.paymentDateFilled')}
              slots={{
                openPickerIcon: EventIcon,
              }}
              slotProps={{
                textField: () => ({
                  sx: { svg: { color: theme.colors.primary.main }, maxWidth: '350px', fontWeight: 400 },
                  variant: 'outlined',
                  error: Boolean(fieldState.error),
                  helperText:
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                }),
              }}
            />
          )}
        />
      )}

      {referenceNumber && (
        <Controller
          name={PAYMENT_REFERENCE_NUMBER}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              variant="outlined"
              label={t('dashboard.paymentDetailsFilled')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'payment reference number input field',
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
              onBlur={() => {
                trigger(PAYMENT_REFERENCE_NUMBER);
              }}
            />
          )}
        />
      )}

      <Box display="flex" justifyContent="end" alignItems="baseline" gap={2}>
        <Button variant="outlined" style={{ padding: '6px 16px' }} color="primary" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          color="primary"
          variant="contained"
          style={{ padding: '6px 16px' }}
        >
          {t('common.saveChanges')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default memo(PaymentDetailsEdit);
