import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, SxProps, Theme, Typography } from '@mui/material';

import PasswordValidationItem from './PasswordValidationItem';
import { StyledList } from './styled';

interface SignUpFormValidationProps {
  errors: string[];
  isTouched: boolean;
  sx?: SxProps<Theme>;
}

const RULES = ['minMax', 'containsDigit', 'containsUppercase'];

const PasswordValidationList: FC<SignUpFormValidationProps> = ({ errors, isTouched, sx: customSx }) => {
  const { t } = useTranslation();

  const sx = useMemo(() => (Array.isArray(customSx) ? customSx : [customSx]), [customSx]);

  return (
    <Box sx={sx}>
      <Typography variant="body2" fontSize={16}>
        {t('common.passwordMustContain')}
      </Typography>
      <StyledList component="ul">
        {RULES.map(r => (
          <PasswordValidationItem key={r} type={r} isError={errors.includes(r)} isTouched={isTouched} />
        ))}
      </StyledList>
    </Box>
  );
};

export default memo(PasswordValidationList);
