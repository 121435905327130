import { ChangeEvent, FC, memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DASH_SYMBOL, MAX_DESCRIPTION_CHARACTERS, MAX_FORMATTED_ABN_SYMBOLS } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import {
  ABN_FIELD,
  CATEGORY_FIELD,
  DESCRIPTION_FIELD,
  HAS_ABN_FIELD,
  MISSING_REASON_FIELD,
  MORE_THAN_ONE_DAY_FIELD,
  SERVICE_END_DATE_FIELD,
  SERVICE_EXACT_DATE_FIELD,
  SERVICE_START_DATE_FIELD,
  TOTAL_AMOUNT_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetails/constants';
import { getCategoriesOptions } from '@pages/InvoiceDetails/utils';
import MissingDetailsDropdowns from '@pages/InvoiceDetailsNew/components/EditableColumn/components/MissingDetailsDropdowns';
import {
  StyledAmountTextField,
  StyledCheckboxFormControlLabel,
  StyledEditTextFieldMaxWidth,
  StyledSwitch,
  StyledSwitchFormControlLabel,
} from '@pages/InvoiceDetailsNew/components/EditableColumn/styled';
import { ZERO_CURRENCY_FORMAT } from '@pages/InvoiceDetailsNew/constants/defaultValues';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { UndoModalId } from '@pages/InvoiceDetailsNew/modals/UndoModal';
import { getReasonsOptions } from '@pages/InvoiceDetailsNew/utils';
import { formatCurrency } from '@utils/formatCurrency';

import EventIcon from '@mui/icons-material/Event';
import { Autocomplete, Box, Button, Checkbox, FormHelperText, InputAdornment, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import AutocompletePaper from '@components/AutocompletePaper';
import LoadingButton from '@components/LoadingButton';
import { theme } from '@components/theme';

const formatABN = (value: string) => {
  const digits = value.replace(/\D/g, '');

  /** capture digits in groups and place spaces between them like so XX XXX XXX XXX */
  return digits.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
};

const EditInvoiceDetailsForm: FC<{ loading?: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { control, watch, setValue, setError, trigger } = useFormContext();
  const {
    supportCategory,
    categoriesOptions,
    invoiceData,
    reasonOptions,
    toggleEdit,
    editInvoice,
    setEditInvoice,
    handleResetForm,
  } = useInvoiceDetailsNewContext();

  const moreThanOneDay = watch(MORE_THAN_ONE_DAY_FIELD);
  const showAbnField = watch(HAS_ABN_FIELD);
  const descField = watch(DESCRIPTION_FIELD);
  const providerField = watch(VENDOR_NAME_FIELD);

  const handleSwitch = async (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (showAbnField) {
      const result = await NiceModal.show(UndoModalId, {
        title: t('dashboard.undoModal.abnRemove.title'),
        text: t('dashboard.undoModal.abnRemove.text'),
        buttonText: t('dashboard.undoModal.abnRemove.buttonText'),
      });

      if (result) {
        setValue(HAS_ABN_FIELD, false);
        setValue(ABN_FIELD, undefined);
      }
      return;
    }

    setValue(HAS_ABN_FIELD, checked);
  };

  const handleCancel = () => {
    toggleEdit(editInvoice, setEditInvoice);
    handleResetForm();
  };

  useEffect(() => {
    if (descField.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(DESCRIPTION_FIELD, {
        type: 'manual',
        message: `${descField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(DESCRIPTION_FIELD);
    }
    if (providerField.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(VENDOR_NAME_FIELD, {
        type: 'manual',
        message: `${descField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(VENDOR_NAME_FIELD);
    }
  }, [descField, providerField.length, setError, trigger]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Controller
        control={control}
        name={DESCRIPTION_FIELD}
        render={({ field, fieldState }) => (
          <Box>
            <StyledEditTextFieldMaxWidth
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.descriptionTitle')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'description input field',
                style: { fontWeight: 400 },
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error ? (
                  t(fieldState.error.message as string)
                ) : (
                  <Box display="flex">
                    <Typography variant="caption">{t('dashboard.updateDetails.descriptionHelpText')}</Typography>
                  </Box>
                )
              }
              {...field}
              onBlur={() => {
                trigger(DESCRIPTION_FIELD);
              }}
            />
          </Box>
        )}
      />

      <Box display="flex">
        <Controller
          control={control}
          name={TOTAL_AMOUNT_FIELD}
          render={({ field, fieldState }) => (
            <StyledAmountTextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.totalAmountTitle')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'total amount input field',
                style: { fontWeight: 400 },
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              {...field}
              value={field.value ? field.value : ZERO_CURRENCY_FORMAT}
              onChange={e => {
                const rawValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal
                field.onChange(rawValue);
              }}
              onBlur={() => {
                if (field.value) {
                  field.onChange(formatCurrency(field.value));
                }
                if (!field.value) {
                  field.onChange(ZERO_CURRENCY_FORMAT);
                }
              }}
              onFocus={() => {
                if (field.value) {
                  const rawValue = field.value.replace(/[^0-9.]/g, '');
                  field.onChange(rawValue);
                }
              }}
            />
          )}
        />
      </Box>

      {/* DATE SECTION */}
      <Box display="flex" flexDirection="column" gap={1}>
        {!moreThanOneDay && (
          <Controller
            control={control}
            name={SERVICE_EXACT_DATE_FIELD}
            render={({ field, fieldState }) => (
              <DatePicker
                {...field}
                label={t('dashboard.updateDetails.serviceDateField')}
                slots={{
                  openPickerIcon: EventIcon,
                }}
                slotProps={{
                  textField: () => ({
                    sx: { svg: { color: theme.colors.primary.main }, maxWidth: '300px', fontWeight: 400 },
                    variant: 'outlined',
                    error: Boolean(fieldState.error),
                    helperText:
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                  }),
                }}
              />
            )}
          />
        )}

        {moreThanOneDay && (
          <Box display="flex" gap={3} alignItems="center">
            <Controller
              control={control}
              name={SERVICE_START_DATE_FIELD}
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label={t('dashboard.updateDetails.serviceStartDateField')}
                  slots={{
                    openPickerIcon: EventIcon,
                  }}
                  slotProps={{
                    textField: () => ({
                      sx: { svg: { color: theme.colors.primary.main }, maxWidth: '250px', fontWeight: 400 },
                      variant: 'outlined',
                      error: Boolean(fieldState.error),
                      helperText:
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                    }),
                  }}
                />
              )}
            />
            <Box>{DASH_SYMBOL}</Box>
            <Controller
              control={control}
              name={SERVICE_END_DATE_FIELD}
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label={t('dashboard.updateDetails.serviceEndDateField')}
                  slots={{
                    openPickerIcon: EventIcon,
                  }}
                  slotProps={{
                    textField: () => ({
                      sx: { svg: { color: theme.colors.primary.main }, maxWidth: '250px', fontWeight: 400 },
                      variant: 'outlined',
                      error: Boolean(fieldState.error),
                      helperText:
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                    }),
                  }}
                />
              )}
            />
          </Box>
        )}
        {/* CHECKBOX */}
        <StyledCheckboxFormControlLabel
          control={
            <Controller
              control={control}
              name={MORE_THAN_ONE_DAY_FIELD}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} onChange={e => field.onChange(e.target.checked)} />
              )}
            />
          }
          labelPlacement="end"
          label={t('dashboard.updateDetails.moreThanOneDayLabel')}
        />
      </Box>

      <Controller
        control={control}
        name={VENDOR_NAME_FIELD}
        render={({ field, fieldState }) => (
          <StyledEditTextFieldMaxWidth
            fullWidth
            variant="outlined"
            label={t('dashboard.updateDetails.providerTitle')}
            type="text"
            autoComplete="off"
            inputProps={{
              'aria-required': 'true',
              'aria-label': 'provider input field',
              style: { fontWeight: 400 },
            }}
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error ? (
                t(fieldState.error.message as string)
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="caption">{`${field.value.length}/${MAX_DESCRIPTION_CHARACTERS}`}</Typography>
                </Box>
              )
            }
            {...field}
            onBlur={() => {
              trigger(VENDOR_NAME_FIELD);
            }}
          />
        )}
      />

      <StyledSwitchFormControlLabel
        control={
          <Controller
            control={control}
            name={HAS_ABN_FIELD}
            render={({ field }) => (
              <StyledSwitch {...field} checked={field.value} color="primary" onChange={handleSwitch} />
            )}
          />
        }
        labelPlacement="end"
        label={t('dashboard.updateDetails.hasAbnLabel')}
      />

      {showAbnField && (
        <Controller
          control={control}
          name={ABN_FIELD}
          render={({ field, fieldState }) => (
            <Box>
              <StyledEditTextFieldMaxWidth
                fullWidth
                variant="outlined"
                label={t('dashboard.updateDetails.providerAbnTitle')}
                type="text"
                autoComplete="off"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'provider abn input field',
                  maxLength: MAX_FORMATTED_ABN_SYMBOLS, // 3 empty spaces for formatted numbers
                  style: { fontWeight: 400, maxWidth: '150px' },
                }}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
                value={formatABN(field.value)}
                onChange={e => {
                  field.onChange(e.target.value.replace(/\D/g, ''));
                }}
              />
              {!fieldState.error && (
                <FormHelperText style={{ marginLeft: '15px' }}>
                  {t('dashboard.updateDetails.abnNumberHelpText')}
                </FormHelperText>
              )}
            </Box>
          )}
        />
      )}

      {invoiceData?.reason && !showAbnField && (
        <Controller
          name={MISSING_REASON_FIELD}
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              options={getReasonsOptions(reasonOptions)}
              disablePortal
              disableClearable
              PaperComponent={AutocompletePaper}
              renderInput={params => (
                <StyledEditTextFieldMaxWidth
                  error={Boolean(fieldState.error)}
                  variant="outlined"
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  label={t('dashboard.abnMissingReason')}
                  {...params}
                />
              )}
              onChange={(_, d) => field.onChange(d)}
            />
          )}
        />
      )}

      {supportCategory && (
        <Controller
          name={CATEGORY_FIELD}
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              options={getCategoriesOptions(categoriesOptions)}
              disablePortal
              disableClearable
              PaperComponent={AutocompletePaper}
              renderInput={params => (
                <StyledEditTextFieldMaxWidth
                  error={Boolean(fieldState.error)}
                  variant="outlined"
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  label={t('dashboard.invoiceDetails.supportCategoryDescription')}
                  {...params}
                />
              )}
              onChange={(_, d) => field.onChange(d)}
            />
          )}
        />
      )}

      {(!supportCategory || !invoiceData?.abn) && <MissingDetailsDropdowns />}

      <Box display="flex" justifyContent="end" alignItems="baseline" gap={2} mt={6}>
        <Button variant="outlined" style={{ padding: '6px 16px' }} color="primary" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          color="primary"
          variant="contained"
          style={{ padding: '6px 16px' }}
        >
          {t('common.saveChanges')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default memo(EditInvoiceDetailsForm);
