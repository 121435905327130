import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';

import { Box, Button, Typography } from '@mui/material';
import SuccessPrimaryIcon from '@components/icons/SuccessPrimaryIcon';

const ForgotPasswordSent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${ROUTING.LOGIN}`, { replace: true });
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <SuccessPrimaryIcon sx={{ alignSelf: 'center' }} />
      <Typography variant="h6">{t('auth.forgotPasswordFinishTitle')}</Typography>
      <Button
        variant="contained"
        disableRipple
        disableTouchRipple
        disableFocusRipple
        color="primary"
        onClick={handleClick}
      >
        {t('common.backToLogin')}
      </Button>
    </Box>
  );
};

export default memo(ForgotPasswordSent);
