import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CONFIG } from '@constants/config';

import { Box, Typography } from '@mui/material';
import { StyledFooterBox } from '@components/Footer/styled';
import LogoIcon from '@components/LogoIcon';

import { StyledLink } from './styled';

const footerLinks = [
  { id: 1, locale: 'dashboard.navigation.links.contact', href: `${CONFIG.domain}${CONFIG.pages.contact}` },
  { id: 2, locale: 'dashboard.navigation.links.termsOfUse', href: `${CONFIG.domain}${CONFIG.pages.termsOfService}` },
  { id: 3, locale: 'dashboard.navigation.links.privacyPolicy', href: `${CONFIG.domain}${CONFIG.pages.privacyPolicy}` },
];

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterBox display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" gap={5} alignItems="center">
        <LogoIcon />
        <Box display="flex" gap={3}>
          {footerLinks.map(({ id, locale, href }) => (
            <StyledLink key={id} to={href} target="_blank">
              <Typography fontWeight={400} fontSize={14}>
                {t(locale)}
              </Typography>
            </StyledLink>
          ))}
        </Box>
      </Box>
      <Box display="flex" gap={1}>
        <Typography variant="caption">
          <Trans i18nKey={t('common.footer.rights')} values={{ year: new Date().getFullYear() }} />
        </Typography>
      </Box>
    </StyledFooterBox>
  );
};

export default Footer;
