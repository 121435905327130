import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewDoc } from '@pages/InvoiceDetailsNew/components/PreviewColumn/types';

import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, styled, Typography } from '@mui/material';

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  flexDirection: 'row',
}));

const PreviewControls: FC<PreviewDoc> = ({ blob, fileName, buttonHeight, fontSize }) => {
  const { t } = useTranslation();

  const handleDownloadBlob = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleOpenInNewWindow = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  };

  return (
    <ResponsiveBox>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={!blob}
        variant="outlined"
        color="primary"
        onClick={handleDownloadBlob}
        endIcon={<DownloadIcon />}
        sx={{ padding: '4px 10px', height: buttonHeight }}
      >
        <Typography fontWeight={500} fontSize={fontSize}>
          {t('common.download')}
        </Typography>
      </Button>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={!blob}
        variant="outlined"
        color="primary"
        endIcon={<OpenInNewIcon />}
        onClick={handleOpenInNewWindow}
        sx={{ padding: '4px 10px', height: buttonHeight }}
      >
        <Typography fontWeight={500} fontSize={fontSize}>
          {t('common.openInNewWindow')}
        </Typography>
      </Button>
    </ResponsiveBox>
  );
};

export default memo(PreviewControls);
