import { FC, PropsWithChildren } from 'react';
import { NAV } from '@providers/layouts/AppLayout/config';
import { StyledMainHeader, StyledTransitionBox } from '@providers/layouts/AppLayout/styled';

import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CollapseIcon from '@components/icons/CollapseIcon';
import ExpandIcon from '@components/icons/ExpandIcon';

const Main: FC<PropsWithChildren & { pageTitle: string; toggleNav: () => void; expanded: boolean }> = ({
  pageTitle,
  toggleNav,
  expanded,
  children,
}) => (
  <StyledTransitionBox
    aria-label="application current view"
    component="nav"
    display="flex"
    flexDirection="column"
    px={2}
    pt={5}
    width={`calc(100% - ${expanded ? NAV.WIDTH : NAV.COLLAPSED_WIDTH}px)`}
    minHeight={1}
  >
    <Paper elevation={3} sx={{ height: '100%', borderRadius: '4px' }}>
      <StyledMainHeader display="flex" gap={2} alignItems="center">
        <IconButton onClick={toggleNav} disableRipple disableTouchRipple disableFocusRipple>
          {expanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
        <Typography variant="h6" fontWeight={500}>
          {pageTitle}
        </Typography>
      </StyledMainHeader>
      <Box height="calc(100%)">{children}</Box>
    </Paper>
  </StyledTransitionBox>
);

export default Main;
