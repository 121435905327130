import { Link, LinkProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPolicyTypography = styled(Typography)<TypographyProps>({
  fontSize: '16px',
  fontWeight: 400,
  maxWidth: 400
});

export const StyledLink = styled(Link)<LinkProps>(() => ({
  textDecoration: 'underline',
  textUnderlineOffset: '3px',
}));

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.secondary.contrast
}));
