import { FC, memo } from 'react';
import { StyledAddDetailsButton } from '@pages/InvoiceDetailsNew/components/EditableColumn/components/AddDetailsButton/styled';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

interface AddDetailsProps {
  onClick: () => Promise<void>;
  text: string;
}

const AddDetailsButton: FC<AddDetailsProps> = ({ onClick, text }) => (
  <StyledAddDetailsButton
    disableRipple
    disableTouchRipple
    disableFocusRipple
    variant="outlined"
    onClick={onClick}
    endIcon={<AddIcon fontSize="small" />}
  >
    <Typography fontSize={13} fontWeight={500} mr={0} pr={0}>
      {text}
    </Typography>
  </StyledAddDetailsButton>
);

export default memo(AddDetailsButton);
