import styled from '@mui/system/styled';

const ExpandIcon = styled(props => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.8947 15.7634V18H0V15.7634H19.8947ZM15.92 0L21 5.13981L15.92 10.2796L14.357 8.69818L17.8738 5.13981L14.357 1.58153L15.92 0ZM9.94741 7.93547V10.172H0V7.93547H9.94741ZM9.94741 0.107591V2.34415H0V0.107591H9.94741Z"
      fill="white"
    />
  </svg>
))({});

export default ExpandIcon;
