import { FC, memo } from 'react';
import { StyledFileItemBox, StyledFileItemName } from '@pages/UploadInvoice/styled';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';

interface FileItemProps {
  name: string;
  onRemove: () => void;
}

const FileItem: FC<FileItemProps> = ({ name, onRemove }) => (
  <StyledFileItemBox display="flex" justifyContent="space-between" p={1.5} width="100%">
    <Box display="flex" alignItems="center" gap={1}>
      <AttachFileIcon color="primary" />
      <StyledFileItemName fontWeight={400} fontSize={16}>
        {name}
      </StyledFileItemName>
    </Box>
    <IconButton onClick={onRemove} aria-label={`Remove ${name}`}>
      <DeleteIcon color="primary" />
    </IconButton>
  </StyledFileItemBox>
);

export default memo(FileItem);
