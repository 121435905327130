import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL_DOMAIN } from '@constants/auth';
import EditId from '@pages/CreateID/components/EditId';
import { StyledEditBox, StyledIdBox } from '@pages/CreateID/components/styled';
import { useCreateIdContext } from '@pages/CreateID/context';
import { StyledTitle } from '@pages/Signup/components/styled';
import { StyledBox, StyledPaper } from '@pages/styled';
import { SnackbarKey } from 'notistack';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';

const CreateIDForm: FC<{ loading: boolean; handleCreate: () => Promise<void> | Promise<SnackbarKey | undefined> }> = ({
  loading,
  handleCreate,
}) => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const { toggleEdit, editId } = useCreateIdContext();
  const emailId = watch('emailId');

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column" aria-label="Create CapsureID form">
      <StyledTitle variant="h3" mb={3} maxWidth={400}>
        {t('auth.signupText')}
      </StyledTitle>
      <Typography variant="h5" mb={4} fontFamily="WFVisualSans">
        {t('auth.signupSubtitle')}
      </Typography>

      <StyledPaper elevation={5}>
        <StyledBox gap={3}>
          <Typography variant="h4">{t('auth.createIdTitle')}</Typography>
          <Typography variant="body1">{t('auth.createIdSubtitle')}</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {editId && <EditId />}
            {!editId && (
              <>
                <Typography variant="body2">{t('auth.createIdText')}</Typography>

                <StyledIdBox>
                  <Typography variant="h6" fontWeight={500}>{`${emailId}${EMAIL_DOMAIN}`}</Typography>
                </StyledIdBox>

                <Box mt={2}>
                  <StyledEditBox onClick={toggleEdit} display="flex" gap={1}>
                    <EditIcon />
                    <Typography variant="body1" fontSize={15} ml={1}>
                      {t('auth.editIdText')}
                    </Typography>
                  </StyledEditBox>
                </Box>
              </>
            )}
          </Box>
          {!editId && (
            <LoadingButton
              fullWidth
              disabled={Object.keys(errors).length > 0}
              variant="contained"
              color="primary"
              loading={loading}
              aria-label="create capsure id confirm button"
              sx={{ mt: 3 }}
              onClick={handleCreate}
            >
              {t('auth.createIdBtnText')}
            </LoadingButton>
          )}
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(CreateIDForm);
