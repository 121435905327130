import { FC, memo } from 'react';
import { NOT_AVAILABLE } from '@constants/common';

import { Typography } from '@mui/material';
import { StyledBox, StyledTitle } from '@components/CredentialDetails/styled';

const CredentialDetails: FC<{ title: string; value?: string | null }> = ({ title, value }) => (
  <StyledBox display="flex" flexDirection="column" gap={1}>
    <StyledTitle>{title}</StyledTitle>
    <Typography fontWeight={400} fontSize={14} lineHeight="20px">
      {value || NOT_AVAILABLE}
    </Typography>
  </StyledBox>
);

export default memo(CredentialDetails);
