import { Dialog, DialogProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

const mapSizes = {
  small: {
    '.MuiPaper-root': {
      maxWidth: '444px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1158px',
      height: '85vh',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'small' | 'medium' | 'xl'; theme?: Theme }>(
  ({ size, theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.colors.background.default,
    },
    zIndex: 1800,
    ...(size && mapSizes[size]),
  }),
);

export const StyledDocViewerWrapper = styled('div')`
  #pdf-controls {
    display: none;
  }
  #image-renderer {
    background-image: none;
  }
`;
