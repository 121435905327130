import styled from '@mui/system/styled';

const SuccessPrimaryIcon = styled(props => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="42" height="42" fill="white" />
    <path
      d="M21 3.5C11.34 3.5 3.5 11.34 3.5 21C3.5 30.66 11.34 38.5 21 38.5C30.66 38.5 38.5 30.66 38.5 21C38.5 11.34 30.66 3.5 21 3.5ZM17.5 29.75L8.75 21L11.2175 18.5325L17.5 24.7975L30.7825 11.515L33.25 14L17.5 29.75Z"
      fill="#8435E9"
    />
  </svg>
))({});

export default SuccessPrimaryIcon;
