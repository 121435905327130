import { ButtonProps, Link, LinkProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLink = styled(Link)<LinkProps & ButtonProps>(({ theme, disabled }) => ({
  color: disabled ? theme.colors.action.disabled : theme.colors.primary.main,
  fontWeight: 400,
  fontSize: '16px',
  textUnderlineOffset: '3px',
}));

export const StyledHint = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  color: theme.colors.action.disabled,
  fontFamily: 'Inter',
}));
