import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useShrink } from '@hooks/useShrink';
import { ForgotPasswordDto } from '@pages/ForgotPassword';
import ForgotPasswordSent from '@pages/ForgotPassword/components/ForgotPasswordSent';
import { StyledBox, StyledLink, StyledLoadingButton, StyledPaper } from '@pages/styled';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, InputAdornment, Typography } from '@mui/material';

import { StyledTextField, StyledTypography } from './styled';

const ForgotPasswordForm: FC<{ loading: boolean; requestSent: boolean }> = ({ loading, requestSent }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ForgotPasswordDto>();
  const emailShrink = useShrink();

  return (
    <>
      <StyledTypography variant="h3" mb="20px" aria-label={t('common.forgotPassword')}>
        {t('common.forgotPassword')}
      </StyledTypography>
      <Typography variant="h5" mb="40px" maxWidth="560px">
        {t('common.forgotPasswordSubtitle')}
      </Typography>
      <StyledPaper elevation={5}>
        <StyledBox>
          {requestSent && <ForgotPasswordSent />}
          {!requestSent && (
            <>
              <Typography variant="h6" aria-label={t('common.forgotPasswordCaption')}>
                {t('common.forgotPasswordCaption')}
              </Typography>
              <Box width="100%" mt={2} mb={5}>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      label={t('common.inputs.email')}
                      type="email"
                      error={Boolean(fieldState.error)}
                      inputProps={{
                        'aria-required': 'true',
                        'aria-label': 'email input field',
                      }}
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: emailShrink.shrink,
                      }}
                      {...field}
                      onFocus={() => emailShrink.setShrink(true)}
                      onBlur={e => {
                        !e.target.value && emailShrink.setShrink(false);
                      }}
                    />
                  )}
                  name="email"
                />
              </Box>
              <StyledLoadingButton
                fullWidth
                variant="contained"
                color="primary"
                loading={loading}
                type="submit"
                aria-label="submit button"
              >
                {t('auth.submitForgotBtnText')}
              </StyledLoadingButton>

              <Box mt={3}>
                <StyledLink component={RouterLink} to={`/${ROUTING.LOGIN}`} replace aria-label="back to login button">
                  <Box display="flex" alignItems="center" gap={2}>
                    <ChevronLeftIcon /> <Typography fontSize={15}>{t('common.backToLogin')}</Typography>
                  </Box>
                </StyledLink>
              </Box>
            </>
          )}
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default memo(ForgotPasswordForm);
