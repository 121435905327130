import { Button, ButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledAddDetailsButton = styled(Button)<ButtonProps & { theme?: Theme }>(({ theme }) => ({
  borderColor: theme.colors.primary.main,
  borderStyle: 'dashed',
  borderRadius: '5px',
  outline: 'transparent',
  borderWidth: '2px',
  padding: '5px 0px',
  width: '243px',
  height: '68px',
  alignItems: 'center',
  justifyContent: 'center',
}));
