import { FC, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_HEADER_RAW_HEIGHT } from '@constants/common';
import EditableColumn from '@pages/InvoiceDetailsNew/components/EditableColumn';
import InfoColumn from '@pages/InvoiceDetailsNew/components/InfoColumn';
import PreviewColumn from '@pages/InvoiceDetailsNew/components/PreviewColumn';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { StyledEditableColumnGrid, StyledInfoColumnGrid } from '@pages/InvoiceDetailsNew/styled';

import { Grid, LinearProgress } from '@mui/material';

const InvoiceDetailsNew: FC = () => {
  const { isLoadingInvoices, invoiceData, isLoadingCategoryOptions, handleResetForm, isLoadingReasons } =
    useInvoiceDetailsNewContext();
  const navigate = useNavigate();

  const isLoading = [isLoadingInvoices, isLoadingCategoryOptions, isLoadingReasons].some(Boolean);

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
  }, [handleResetForm, invoiceData, navigate]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Grid container columns={12} height={`calc(100% - ${PAGE_HEADER_RAW_HEIGHT}px)`} width="auto">
      {/* First section: InfoColumn */}
      <StyledInfoColumnGrid item xs={12} md={3} display="flex" flexDirection="column" gap={3}>
        <InfoColumn data={invoiceData} />
      </StyledInfoColumnGrid>

      {/* Second section: Editable column */}
      <StyledEditableColumnGrid item xs={12} md={5} display="flex" flexDirection="column" gap={2}>
        <EditableColumn />
      </StyledEditableColumnGrid>

      {/* Third section: PreviewColumn */}
      <Grid item xs={12} md={4} style={{ backgroundColor: '#fcfcfc', padding: '20px' }}>
        {/* Content for document preview */}
        <PreviewColumn />
      </Grid>
    </Grid>
  );
};

export default memo(InvoiceDetailsNew);
