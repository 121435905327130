import { ChangeEvent, useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { UndoModalId } from '@pages/InvoiceDetailsNew/modals/UndoModal';

export const useHandleCheckboxes = () => {
  const handleStateChange = useCallback(
    async (
      event: ChangeEvent<HTMLInputElement>,
      isChecked: boolean,
      setState: (value: boolean) => void,
      modalConfig: { title: string; text: string; buttonText: string },
    ) => {
      const { checked } = event.target;

      if (isChecked) {
        const result = await NiceModal.show(UndoModalId, modalConfig);

        if (result) {
          setState(false);
        }
        return result;
      }

      setState(checked);
      return true;
    },
    [],
  );

  return { handleStateChange };
};
