import { FC, memo, PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePages } from '@providers/layouts/AppLayout/hooks/usePages';
import { StyledAppLayoutWrapper } from '@providers/layouts/AppLayout/styled';

import Box from '@mui/material/Box';
import Footer from '@components/Footer';

import Main from './components/Main';
import Navigation from './components/Navigation';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const [expanded, setExpanded] = useState(true);
  const handleToggleNav = () => setExpanded(!expanded);
  const location = useLocation();
  const pagesConfig = usePages();
  const pageTitle = pagesConfig.filter(obj => location.pathname === obj.path)[0]?.title;

  return (
    <StyledAppLayoutWrapper display="flex" flexDirection="column" minHeight="100vh">
      <Box display="flex" flex={1} pb={2}>
        <Navigation expanded={expanded} />
        <Main pageTitle={pageTitle} expanded={expanded} toggleNav={handleToggleNav}>
          {children}
        </Main>
      </Box>
      <Footer />
    </StyledAppLayoutWrapper>
  );
};

export default memo(AppLayout);
