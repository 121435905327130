import { AvatarProps, Box, BoxProps, Divider, DividerProps, Link, LinkProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { styled, Theme } from '@mui/material/styles';

export const StyledAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  backgroundColor: theme.colors.primary.main,
}));

export const StyledAppLayoutWrapper = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.background.default,
  minWidth: '1728px',
}));

export const StyledMainHeader = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.primary.main,
  color: theme.colors.white,
  height: 70,
  padding: '24px 32px',
  borderRadius: '4px 4px 0 0',
}));

export const StyledNavigationIdBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.light,
  padding: '10px 15px',
  textAlign: 'center',
  height: '45px',
  borderRadius: '4px',
}));

export const StyledShareBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  border: 'transparent',
  outline: 'transparent',
  boxShadow: 'none',
  color: theme.colors.primary.main,
  height: 30,
  width: 100,
  cursor: 'pointer',
}));

export const StyledLink = styled(Link)<LinkProps>(() => ({
  textDecoration: 'none',
  color: 'black',
}));

export const StyledDivider = styled(Divider)<DividerProps>({
  margin: '4px 0',
});

export const StyledTransitionBox = styled(Box)<BoxProps>({
  transition: 'width .25s ease',
});

export const StyledTextOverflowBox = styled(Box)<BoxProps>({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
});
