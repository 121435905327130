import { FC, memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  api,
  useAuthSigninCreateMutation,
  useAuthSignupResendEmailCreateMutation,
  useAuthSignupVerifyEmailCreateMutation,
} from '@api/api';
import { ERROR, RESEND_TIMER } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '@hooks/api/useUser';
import { loginSuccess } from '@store/authSlice';
import { getErrorMessage } from '@utils/getMessage';
import { saveToStorage } from '@utils/manipulateStorage';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { Box } from '@mui/material';

import ConfirmEmailForm from './components/ConfirmEmailForm';

const schema = yup.object().shape({
  code: yup.array().of(yup.string().min(1).required()).min(6).required(),
});

const ConfirmEmail: FC = () => {
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [countdownTime, setCountdownTime] = useState<number>(RESEND_TIMER);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const email = state?.email as string;
  const password = state?.password as string;
  const rememberMe = state?.rememberMe as boolean;

  const { data: userData } = useUser();

  const form = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const [confirmEmailMutation, { isLoading: isLoadingConfirmEmail }] = useAuthSignupVerifyEmailCreateMutation();
  const [resendCodeMutation] = useAuthSignupResendEmailCreateMutation();
  const [loginMutation, { isLoading: loginIsLoading }] = useAuthSigninCreateMutation();

  const isLoading = [isLoadingConfirmEmail, loginIsLoading].some(Boolean);

  const handleResend = useCallback(async () => {
    try {
      setCodeSent(true);
      setCountdownTime(RESEND_TIMER);

      await resendCodeMutation({ resendEmailVerificationRequest: { email } });
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  }, [email, resendCodeMutation, snackbar]);

  const onConfirm: SubmitHandler<{ code: string[] }> = useCallback(
    async ({ code }) => {
      try {
        setCodeSent(true);
        const response = await confirmEmailMutation({ body: { key: code.join('') } }).unwrap();

        saveToStorage(rememberMe, response);

        const loginResponse = await loginMutation({
          loginRequest: { email, password },
        }).unwrap();

        dispatch(api.util.resetApiState());
        dispatch(loginSuccess(loginResponse));

        navigate(`/${ROUTING.CREATE_ID}`, { state: { email, password, rememberMe } });
      } catch (err) {
        // @ts-ignore
        if (err.status === 404) {
          form.setError('code', { type: 'not_found' });
        } else {
          snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
        }
      }
    },
    [confirmEmailMutation, dispatch, email, form, loginMutation, navigate, password, rememberMe, snackbar],
  );

  if (userData) return <Navigate to={ROUTING.ROOT} replace />;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.confirmEmail')}</title>
      </Helmet>
      <Box display="flex" width="100%" justifyContent="center" alignItems="center" aria-label="confirm email page">
        <FormProvider {...form}>
          <Box component="form" height="930px" onSubmit={form.handleSubmit(onConfirm)}>
            <ConfirmEmailForm
              loading={isLoading}
              handleResend={handleResend}
              isCodeSent={codeSent}
              setCodeSent={setCodeSent}
              countdown={countdownTime}
              setCountdown={setCountdownTime}
              myEmail={email}
            />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(ConfirmEmail);
