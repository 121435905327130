import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledTextButton } from '@pages/InvoiceDetailsNew/components/InfoColumn/styled';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@components/LoadingButton';
import { theme } from '@components/theme';

import { StyledModal } from '../styled';

const AddDateModal = NiceModal.create(
  ({
    title,
    label,
    setDate,
    updateMutation,
    mutationIsLoading,
  }: {
    title: string;
    label: string;
    setDate: (value: string) => void;
    updateMutation: (isoDate: string) => Promise<void>;
    mutationIsLoading: boolean;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleConfirmClick = async () => {
      if (selectedDate) {
        setDate(selectedDate.toISOString());
        await updateMutation(selectedDate.toISOString());
      }
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onClose={handleCancelClick}>
        <Box padding="16px 24px">
          <Typography variant="h6" fontFamily="WFVisualSans" fontWeight={500} mb={2}>
            {t(title)}
          </Typography>

          <Box>
            <DatePicker
              value={selectedDate}
              onChange={newDate => setSelectedDate(newDate)}
              label={label}
              slots={{
                openPickerIcon: CalendarTodayIcon,
              }}
              slotProps={{
                textField: () => ({
                  sx: {
                    svg: { color: theme.colors.primary.main },
                    fontWeight: 400,
                    '&.MuiFormControl-root': { width: '100%' },
                  },
                  variant: 'outlined',
                }),
              }}
            />
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <StyledTextButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="text"
              color="primary"
              onClick={handleCancelClick}
            >
              {t('common.cancel')}
            </StyledTextButton>
            <LoadingButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="contained"
              loading={mutationIsLoading}
              color="primary"
              onClick={handleConfirmClick}
              disabled={!selectedDate}
              type="submit"
              sx={{ maxHeight: '35px' }}
            >
              {t('common.save')}
            </LoadingButton>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const AddDateModalId = 'AddDateModal';

NiceModal.register(AddDateModalId, AddDateModal);
