export const DEFAULT_VALUES = {
  recipient_email: undefined,
  vendor_name: undefined,
  invoice_date: undefined,
  due_date: undefined,
  updated_at: undefined,
  service_exact_date: undefined,
  service_start_date: undefined,
  service_end_date: undefined,
  customer_name: undefined,
  customer_address: undefined,
  ibn: undefined,
  items: [],
  sub_total: undefined,
  total_tax: undefined,
  total_amount: undefined,
  currency_symbol: undefined,
  currency: undefined,
  category: undefined,
  support_more_date: undefined,
  has_abn: undefined,
};

export const ZERO_CURRENCY_FORMAT = '0.00';
