export const ITEMS_FIELD = 'items';
export const RECIPIENT_EMAIL_FIELD = 'recipient_email';
export const VENDOR_EMAIL_FIELD = 'vendor_email';
export const VENDOR_NAME_FIELD = 'vendor_name';
export const INVOICE_DATE_FIELD = 'invoice_date';
export const DUE_DATE_FIELD = 'due_date';
export const UPDATED_AT_FIELD = 'updated_at';
export const SERVICE_START_DATE_FIELD = 'service_start_date';
export const SERVICE_END_DATE_FIELD = 'service_end_date';
export const SERVICE_EXACT_DATE_FIELD = 'service_exact_date';
export const ABN_FIELD = 'abn';
export const CUSTOMER_NAME_FIELD = 'customer_name';
export const CUSTOMER_ADDRESS_FIELD = 'customer_address';
export const CURRENCY_FIELD = 'currency';
export const CURRENCY_SYMBOL_FIELD = 'currency_symbol';
export const SUB_TOTAL_FIELD = 'sub_total';
export const TOTAL_TAX_FIELD = 'total_tax';
export const TOTAL_AMOUNT_FIELD = 'total_amount';
export const DESCRIPTION_FIELD = 'description';
export const UNIT_PRICE_FIELD = 'unit_price';
export const QUANTITY_FIELD = 'quantity';
export const CURRENCY_CODE_FIELD = 'currency_code';
export const AMOUNT_FIELD = 'amount';
export const CATEGORY_FIELD = 'category';
export const MORE_THAN_ONE_DAY_FIELD = 'support_more_date'; // checkbox
export const MISSING_REASON_FIELD = 'missing_reason';
export const MISSING_CATEGORY_FIELD = 'missing_category';
export const HAS_ABN_FIELD = 'has_abn';
export const PAYMENT_DATE_FIELD = 'paid_date';
export const PAYMENT_REFERENCE_NUMBER = 'paid_reference_number';
export const CLAIM_DATE_FIELD = 'claimed_date';
export const CLAIM_ID_FIELD = 'claimed_reference_number';
