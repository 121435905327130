import { Button, ButtonProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import styled from '@mui/system/styled';

export const StyledTextButton = styled(Button)<ButtonProps & { maxWidth?: string }>(({ maxWidth }) => ({
  maxWidth: maxWidth || '100%',
  paddingLeft: 0,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  '& .MuiTypography-root': { fontWeight: 400 },
});
