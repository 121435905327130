import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { INVOICE_ID } from '@constants/common';
import { ROUTING } from '@constants/routing';
import ConfirmEmail from '@pages/ConfirmEmail';
import CreateCapsureId from '@pages/CreateID';
import { CreateIdProvider } from '@pages/CreateID/context';
import ForgotPassword from '@pages/ForgotPassword';
import InvoiceDetailsNew from '@pages/InvoiceDetailsNew';
import { InvoiceDetailsNewProvider } from '@pages/InvoiceDetailsNew/context';
import Invoices from '@pages/Invoices';
import { InvoicesProvider } from '@pages/Invoices/context';
import Login from '@pages/Login';
import NotFound from '@pages/NotFound';
import ResetPassword from '@pages/ResetPassword';
import Signup from '@pages/Signup';
import UploadInvoice from '@pages/UploadInvoice';
import ErrorBoundary from '@providers/layouts/ErrorBoundary';
import PrivatePreviewLayout from '@providers/layouts/PrivatePreviewLayout/PrivatePreviewLayout';

import Loading from '@components/Loading/Loading';
import RegistrationLayout from '@components/RegistrationView';

import PublicRegistrationGuard from './guards/PublicRegistrationGuard';
import PrivateRouteOutlet from './PrivateRouteOutlet';
import PrivateRoutes from './PrivateRoutes';

const PUBLIC_ROUTES = [
  { path: ROUTING.LOGIN, Component: Login },
  { path: ROUTING.SIGNUP, Component: Signup },
  { path: ROUTING.FORGOT_PASSWORD, Component: ForgotPassword },
  { path: ROUTING.RESET_PASSWORD, Component: ResetPassword },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <PrivateRoutes />
      </ErrorBoundary>
    ),
    children: [
      {
        element: (
          <PrivatePreviewLayout>
            <PrivateRouteOutlet />
          </PrivatePreviewLayout>
        ),
        children: [
          {
            index: true,
            element: (
              <InvoicesProvider>
                <Invoices />
              </InvoicesProvider>
            ),
          },
          {
            path: `${ROUTING.INVOICE_DETAILS}/:${INVOICE_ID}`,
            element: (
              <InvoiceDetailsNewProvider>
                <InvoiceDetailsNew />
              </InvoiceDetailsNewProvider>
            ),
          },
          {
            path: ROUTING.INVOICE_UPLOAD,
            element: <UploadInvoice />,
          },
        ],
      },
      {
        path: ROUTING.CONFIRM_EMAIL,
        element: (
          <Suspense fallback={<Loading />}>
            <RegistrationLayout>
              <ConfirmEmail />
            </RegistrationLayout>
          </Suspense>
        ),
      },
      {
        path: ROUTING.CREATE_ID,
        element: (
          <Suspense fallback={<Loading />}>
            <RegistrationLayout>
              <CreateIdProvider>
                <CreateCapsureId />
              </CreateIdProvider>
            </RegistrationLayout>
          </Suspense>
        ),
      },
    ],
  },
  ...PUBLIC_ROUTES.map(({ path, Component }) => ({
    path,
    element: (
      <Suspense fallback={<Loading />}>
        <PublicRegistrationGuard>
          <RegistrationLayout>
            <Component key={path} />
          </RegistrationLayout>
        </PublicRegistrationGuard>
      </Suspense>
    ),
  })),
  {
    path: '*',
    element: (
      <Suspense>
        <NotFound />
      </Suspense>
    ),
  },
]);

const Routing = () => <RouterProvider router={router} />;

export default Routing;
