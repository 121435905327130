import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import { useLogout } from '@hooks/useLogout';
import { usePathname } from '@hooks/usePathname';
import TrialSection from '@providers/layouts/AppLayout/components/TrialSection';
import UsefullLinks from '@providers/layouts/AppLayout/components/UsefullLinks';
import UserId from '@providers/layouts/AppLayout/components/UserId';
import {
  StyledAvatar,
  StyledDivider,
  StyledTextOverflowBox,
  StyledTransitionBox,
} from '@providers/layouts/AppLayout/styled';
import RouterLink from '@routes/components/RouterLink';

import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Logo from '@components/Logo';
import LogoIcon from '@components/LogoIcon';

import { NAV } from '../config';
import navConfig from '../config/navigation';

interface NavItemProps {
  item: {
    icon: JSX.Element;
    path: string;
    title: string;
  };
  expanded: boolean;
}

const NavItem: FC<NavItemProps> = ({ item, expanded }) => {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      role="menuitem"
      sx={{
        minHeight: 34,
        minWidth: '100%',
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        alignSelf: expanded ? 'start' : 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: theme => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" width={24} height={24} mr={expanded ? 2 : 0}>
        {item.icon}
      </Box>

      {expanded && (
        <Box component="span" sx={{ textWrap: 'nowrap' }} aria-label={`link to ${item.title}`}>
          {item.title}
        </Box>
      )}
    </ListItemButton>
  );
};

const Navigation: FC<{ expanded: boolean }> = ({ expanded }) => {
  const { data } = useUser();
  const { logout } = useLogout();
  const { t } = useTranslation();

  return (
    <StyledTransitionBox
      aria-label="app navigation bar"
      aria-roledescription="navigation bar to traverse between pages"
      role="navigation"
      width={expanded ? NAV.WIDTH : NAV.COLLAPSED_WIDTH}
      pt={5}
    >
      <Box
        display="flex"
        flexDirection="column"
        aria-label="navigation scrollbar"
        height="100%"
        gap={3}
        mx={expanded ? 5 : 2}
      >
        <StyledTextOverflowBox
          display="flex"
          flexDirection="column"
          gap={5}
          alignItems={expanded ? 'start' : 'center'}
          ml={expanded ? 1 : 0}
        >
          {expanded ? (
            <Box height={70} display="flex" alignItems="center" ml="-5px">
              <Logo aria-label="company logo with name" />
            </Box>
          ) : (
            <Box height={70} display="flex" alignItems="center">
              <LogoIcon aria-label="company logo" />
            </Box>
          )}
          <Box display="flex" gap={2} alignItems="center">
            <Box height={65}>
              <StyledAvatar>{data?.username[0].toUpperCase()}</StyledAvatar>
            </Box>
            {expanded && (
              <Box>
                <Typography variant="h5" fontFamily="WFVisualSans">
                  {t('dashboard.navigation.user.welcomeBack')}
                </Typography>
                <Typography variant="h5" fontFamily="WFVisualSans">
                  {data?.first_name || ''}
                </Typography>
              </Box>
            )}
          </Box>
        </StyledTextOverflowBox>

        <Stack component="nav" spacing={0.5} aria-label="list of pages" role="menu" mt={2}>
          {navConfig.map(item => (
            <NavItem key={item.title} item={item} expanded={expanded} />
          ))}
        </Stack>

        {expanded && <StyledDivider />}
        {expanded && <UserId expanded={expanded} />}
        {expanded && <StyledDivider />}
        {expanded && <TrialSection expanded={expanded} />}
        {expanded && <StyledDivider />}

        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          flexGrow={1}
          mt={5}
          role="none"
          width={expanded ? NAV.WIDTH - NAV.COLLAPSED_WIDTH : NAV.COLLAPSED_WIDTH}
        >
          {expanded && (
            <>
              <UsefullLinks />
              <Button variant="outlined" onClick={logout}>
                {t('auth.logout')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </StyledTransitionBox>
  );
};

export default memo(Navigation);
