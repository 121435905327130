import styled from '@mui/system/styled';

const SuccessSecondaryIcon = styled(props => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" fill="white" />
    <path
      opacity="0.3"
      d="M19.9998 6.6665C12.6498 6.6665 6.6665 12.6498 6.6665 19.9998C6.6665 27.3498 12.6498 33.3332 19.9998 33.3332C27.3498 33.3332 33.3332 27.3498 33.3332 19.9998C33.3332 12.6498 27.3498 6.6665 19.9998 6.6665ZM16.6665 28.3332L9.99984 21.6665L12.3498 19.3165L16.6665 23.6165L27.6498 12.6332L29.9998 14.9998L16.6665 28.3332Z"
      fill="#8435E9"
    />
    <path
      d="M20.0002 3.3335C10.8002 3.3335 3.3335 10.8002 3.3335 20.0002C3.3335 29.2002 10.8002 36.6668 20.0002 36.6668C29.2002 36.6668 36.6668 29.2002 36.6668 20.0002C36.6668 10.8002 29.2002 3.3335 20.0002 3.3335ZM20.0002 33.3335C12.6502 33.3335 6.66683 27.3502 6.66683 20.0002C6.66683 12.6502 12.6502 6.66683 20.0002 6.66683C27.3502 6.66683 33.3335 12.6502 33.3335 20.0002C33.3335 27.3502 27.3502 33.3335 20.0002 33.3335ZM27.6502 12.6335L16.6668 23.6168L12.3502 19.3168L10.0002 21.6668L16.6668 28.3335L30.0002 15.0002L27.6502 12.6335Z"
      fill="#25175F"
    />
  </svg>
))({});

export default SuccessSecondaryIcon;
