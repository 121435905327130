import { Grid } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { GridProps } from '@mui/system';

export const StyledInfoColumnGrid = styled(Grid)<GridProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  padding: '30px',
  zIndex: 2,
  boxShadow:
    '2px 0px 5px 0px rgba(0, 0, 0, 0.2), 2px 0px 2px 0px rgba(0, 0, 0, 0.14), 2px 0px 3px -2px rgba(0, 0, 0, 0.12)',
}));

export const StyledEditableColumnGrid = styled(Grid)<GridProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  padding: '30px 50px',
  zIndex: 1,
}));
