import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';


export const StyledIdBox = styled(Box)<BoxProps & { theme?: Theme }>(({theme}) => ({
  backgroundColor: theme.colors.secondary.light,
  padding: '16px',
  textAlign: 'center',
  height: '65px',
  borderRadius: '4px',
}));

export const StyledEditBox = styled(Box)<BoxProps & { theme?: Theme }>(({theme}) => ({
  border: 'transparent',
  outline: 'transparent',
  boxShadow: 'none',
  color: theme.colors.primary.main,
  height: 30,
  width: 100,
  cursor: 'pointer'
}))

export const StyledCancelBtn = styled(Button)<ButtonProps>({
  fontWeight: 500,
})

export const StyledBoxWrapper = styled(Box)<BoxProps>({
  '& .MuiFormControl-root .MuiFormHelperText-root': {
    display: 'none'
  }
})
