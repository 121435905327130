import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledEditSectionButton = styled(Button)<ButtonProps>({
  maxWidth: '200px',
  padding: '5px',
  fontWeight: 500,
  fontSize: '13px',
  height: '30px',
});

export const StyledTitle = styled(Typography)<TypographyProps & { hasError?: boolean; theme?: Theme }>(
  ({ hasError, theme }) => ({
    fontWeight: 400,
    color: hasError ? theme.colors.error.main : theme.colors.action.active,
  }),
);

export const StyledBox = styled(Box)<BoxProps>({
  overflowWrap: 'break-word',
});

export const StyledAlertAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'hasValue',
})<{ hasValue?: boolean }>(({ theme, hasValue }) => ({
  border: `2px solid ${hasValue ? 'inherit' : theme.colors.warning.main}`,
  borderRadius: '5px',
  flex: 1,
  maxWidth: '500px',
  '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '5px',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: hasValue ? '#F1F1F1' : 'transparent',
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
}));

export const StyledEditTextFieldMaxWidth = styled(TextField)<TextFieldProps>({
  maxWidth: '600px',
});

export const StyledAmountTextField = styled(TextField)<TextFieldProps>({
  maxWidth: '300px',
  '& .MuiInputAdornment-root': {
    marginRight: 0,
  },
});

export const StyledCheckboxFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 400,
  },
});

export const StyledSwitchFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  '& .MuiTypography-root': {
    fontWeight: 400,
  },
  marginTop: '-18px',
});

export const StyledSwitch = styled(Switch)<SwitchProps>({
  '& .MuiSwitch-thumb': {
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
  },
});
