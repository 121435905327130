import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import MissingDetailsDropdowns from '@pages/InvoiceDetailsNew/components/EditableColumn/components/MissingDetailsDropdowns';

import { Box } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

const MissingDetailsForm: FC<{ loading: boolean }> = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <MissingDetailsDropdowns />

      <LoadingButton
        fullWidth
        type="submit"
        color="secondary"
        loading={loading}
        sx={{ width: '150px', padding: '5px 10px' }}
      >
        {t('common.save')}
      </LoadingButton>
    </Box>
  );
};

export default memo(MissingDetailsForm);
