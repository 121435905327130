import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '@providers/layouts/AppLayout/styled';

import { Box, Typography } from '@mui/material';

const UsefullLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <StyledLink href="#" target="_blank">
        <Typography variant="body1" fontWeight={400}>
          {t('dashboard.navigation.links.yourAccount')}
        </Typography>
      </StyledLink>
      <StyledLink href="#" target="_blank">
        <Typography variant="body1" fontWeight={400}>
          {t('dashboard.navigation.links.howToUse')}
        </Typography>
      </StyledLink>
      <StyledLink href="#" target="_blank">
        <Typography variant="body1" fontWeight={400}>
          {t('dashboard.navigation.links.supportFaq')}
        </Typography>
      </StyledLink>
    </Box>
  );
};

export default memo(UsefullLinks);
