import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { api, useAuthSigninCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from '@pages/Login/types';
import { clearConfirm, confirmSuccess, loginSuccess, signupSuccess } from '@store/authSlice';
import { getErrorMessage } from '@utils/getMessage';
import { saveToStorage } from '@utils/manipulateStorage';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { Box } from '@mui/material';

import LoginForm from './components/LoginForm';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().minMax(8, 25).containsDigit().containsUppercase().required(),
  rememberMe: yup.boolean().required(),
});

const Login: FC = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const form = useForm<LoginSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: undefined,
      password: undefined,
      rememberMe: false,
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const [loginMutation, { isLoading: loginIsLoading }] = useAuthSigninCreateMutation();

  const dispatch = useDispatch();

  const onSignIn: SubmitHandler<LoginSchema> = async ({ email, password, rememberMe }) => {
    try {
      const response = await loginMutation({ loginRequest: { email, password } }).unwrap();

      if (response.access) {
        saveToStorage(rememberMe, response);

        if (response.user.invoice_email === null) {
          dispatch(signupSuccess());
          return navigate(`/${ROUTING.CREATE_ID}`, { state: { email, password, rememberMe } });
        }

        dispatch(api.util.resetApiState());
        dispatch(loginSuccess(response));
        dispatch(confirmSuccess());
      }
    } catch (err) {
      // @ts-ignore
      if (err.data.error.fields.non_field_errors.includes('E-mail is not verified.')) {
        dispatch(clearConfirm());
        return navigate(`/${ROUTING.CONFIRM_EMAIL}`, { state: { email, password } });
      }
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.login')}</title>
      </Helmet>
      <Box display="flex" width="100%" justifyContent="center" alignItems="center" aria-label="login page">
        <FormProvider {...form}>
          <Box component="form" height="540px" onSubmit={form.handleSubmit(onSignIn)}>
            <LoginForm loading={loginIsLoading} />
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(Login);
